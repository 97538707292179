import {action, observable} from "mobx";
import DiscordRole from "../types/DiscordRole";
import api from "../../services/API";
import Editable from "./Editable";
import Model from "../types/Model";

export class Rank extends Editable implements Model {
    id?: number;
    guildId?: string;
    @observable roleId?: string;
    @observable reqLevel?: number;
    @observable reqCurrency?: number;
    @observable reqRoleId?: string;
    fallbackName?: string;

    constructor(json?: any) {
        super(json);
        if (json) {
            this.update(json);
        }
    }

    @action
    update(json: any) {
        super.update(json);
        this.id = json.id;
        this.guildId = json.guildId;
        this.roleId = json.roleId;
        this.reqLevel = json.reqLevel;
        this.reqCurrency = json.reqCurrency;
        this.reqRoleId = json.reqRoleId;
        this.fallbackName = json.fallbackName;
    }

    @action
    setGuildId(guildId: string) {
        this.guildId = guildId;
    }

    @action
    setRole(role?: DiscordRole) {
        this.roleId = role ? role.id : undefined;
        this.fallbackName = role ? role.name : undefined;
    }

    @action
    setRoleId(roleId: string) {
        this.roleId = roleId;
    }

    @action
    setReqLevel(reqLevel?: number) {
        this.reqLevel = reqLevel;
    }

    @action
    setReqCurrency(reqCurrency?: number) {
        this.reqCurrency = reqCurrency;
    }

    @action
    setReqRoleId(reqRoleId?: string) {
        this.reqRoleId = reqRoleId;
    }

    @action
    async postToServer() {
        const {data} = await api.post(`guilds/${this.guildId}/ranks`, Object.assign(this, {id: undefined}));
        this.update({editing: false, isNew: false, ...data});
    }

    @action
    async deleteFromServer() {
        await api.delete(`guilds/${this.guildId}/ranks/${this.id}`);
    }

    @action
    async putToServer() {
        await api.put(`guilds/${this.guildId}/ranks/${this.id}`, this);
    }

    get isComplete() {
        return !!this.roleId;
    }

}

export default Rank;

import * as React from "react";
import api from "../../../services/API";
import {Alert, Button, Card, Descriptions, Modal, Typography} from "antd";
import moment from "moment";
import {StoreContext} from "../../../stores/Stores";
import GuildName from "../../../components/GuildName";
import displayPlan from "../../../util/displayPlan";
import config from "../../../app.config";
import PaymentMethod from "../components/PaymentMethod";

interface SubscriptionsState {
    subscriptions?: any[];
    paymentMethods?: any[];
}

export class Subscriptions extends React.Component {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    state: SubscriptionsState = {};

    componentDidMount() {
        this.fetchSubscriptions();
        this.fetchPaymentMethods();
    }

    async fetchSubscriptions() {
        const {data} = await api.get('premium/subscriptions');
        data.sort((s1: any, s2: any) => {
            if (s1.status !== 'incomplete' && s2.status === 'incomplete') return 1;
            if (s1.status === 'incomplete' && s2.status !== 'incomplete') return -1;
            if (s1.status !== 'active' && s2.status === 'active') return 1;
            if (s1.status === 'active' && s2.status !== 'active') return -1;
            return s1.current_period_end - s2.current_period_end;
        });
        this.setState({subscriptions: data});
    }

    fetchPaymentMethods = async () => {
        const {data} = await api.get('premium/payment-methods');
        this.setState({paymentMethods: data});
    };

    async removeSubscription(subscriptionId: string) {
        await api.delete(`premium/subscriptions/${subscriptionId}`);
        this.setState({
            subscriptions: this.state.subscriptions?.map(s => {
                if (s.id == subscriptionId) {
                    s.status = 'canceled';
                }
                return s;
            })
        });
    }

    onRemove = (subscription: any) => {
        const guildName = this.context.domainStore.findGuildById(subscription.metadata.guildId)?.name ?? 'this guild';
        const endDate = moment(subscription.current_period_end * 1000).format('MMMM Do, YYYY');

        Modal.confirm({
            title: 'Are you sure?',
            content: <span>If you remove this subscription, <Typography.Text strong>{guildName}</Typography.Text>&nbsp;
                will lose access to all its premium features at the end of the billing period.</span>,
            onOk: async () => {
                try {
                    await this.removeSubscription(subscription.id);
                    Modal.success({
                        title: 'Subscription Removed',
                        content: <span><Typography.Text
                            strong>{guildName}</Typography.Text> will lose access to all&nbsp;
                            premium features on <Typography.Text strong>{endDate}</Typography.Text>.</span>
                    });
                } catch (e) {
                    Modal.error({
                        title: 'Something went wrong',
                        content: (
                            <div>We were unable to remove your subscription. Please contact someone in the&nbsp;
                                <a href={config.supportDiscord} target='_blank'>support server</a> or email us at&nbsp;
                                <a href={`mailto:${config.supportEmail}?Subject=Cancel%20Subscription`}
                                   target='_blank'>{config.supportEmail}</a>.
                            </div>
                        )
                    });
                }
            },
            maskClosable: true
        });
    };

    render() {
        const display = !this.state.subscriptions?.length ? 'none' : undefined;

        return (
            <Card className='subscriptions' title='Your Subscriptions' style={{display}}>
                <div className='card-list'>
                    {this.state.subscriptions?.map(s => {
                        const productName = s.plan.product.name;
                        const startDate = moment(s.created * 1000).format('MMMM Do, YYYY');
                        const nextPayment = moment(s.current_period_end * 1000).format('MMMM Do, YYYY');
                        const plan = displayPlan(s.plan);
                        const paymentMethod = this.state.paymentMethods?.find(p => p.id === s.default_payment_method);
                        const guild = this.context.domainStore.findGuildById(s.metadata.guildId);
                        const guildName = guild ? <GuildName guild={guild}/> : s.metadata.guildId;
                        const active = s.status === 'active';
                        const incomplete = s.status === 'incomplete';
                        const cancelled = !(active || incomplete);

                        return (
                            <Card key={s.id} title={<span>{guildName}: <Typography.Text
                                type='secondary'>{productName}</Typography.Text></span>}
                                  extra={
                                      <Button type='primary' danger ghost disabled={cancelled} onClick={() => this.onRemove(s)}>
                                          {cancelled ? 'Canceled' : 'Cancel'}
                                      </Button>
                                  }>
                                {incomplete &&
                                <Alert type='warning'
                                       message='Payment incomplete. Authorize the payment through your payment provider to activate premium.'/>
                                }
                                <Descriptions layout='horizontal' column={2}>
                                    <Descriptions.Item label='Plan'>{plan}</Descriptions.Item>
                                    {paymentMethod &&
                                    <Descriptions.Item label='Payment Method'><PaymentMethod
                                        method={paymentMethod}/></Descriptions.Item>
                                    }
                                    <Descriptions.Item label='Start Date'>{startDate}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={cancelled ? 'End Date' : 'Next Payment'}>{nextPayment}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        )
                    })}
                </div>
            </Card>
        )
    }
}

export default Subscriptions;

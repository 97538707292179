import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import stores, {StoreContext} from "../stores/Stores";
import GuildName from "../components/GuildName";
import Routes, {PageTitles} from "../Routes";
import {Button, Dropdown, Menu, PageHeader, Typography} from "antd";
import User from "../stores/models/User";
import UserAvatar from "../components/UserAvatar";
import {observer} from "mobx-react";
import {auth} from "../services/Authenticator";
import Loader from "../stores/util/Loader";

@observer
export class PHeader extends React.Component<RouteComponentProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();

    componentDidMount() {
        this.loader.load(stores.userStore.fetchUser());
    }

    onPremiumClick = () => {
        this.props.history.push(Routes.premium);
    };

    render() {
        const route = this.props.location.pathname;
        const guild = this.context.domainStore.guild;
        const user = this.context.userStore.user;
        const title = guild ? (<GuildName guild={guild}/>) : 'Purity Dashboard';
        const subTitle = PageTitles[route];
        return <PageHeader ghost={false} title={<h1>{title}</h1>} subTitle={subTitle}
                           extra={
                               <div>
                                   {/*<Button type='primary' style={{marginRight: 10}} onClick={this.onPremiumClick}>Get Premium!</Button>*/}
                                   {user && <ProfileDropdown user={user}/>}
                               </div>
                           }/>;
    }
}

interface ProfileDropdownProps extends RouteComponentProps {
    user: User;
}

const ProfileDropdown = withRouter(({user, ...props}: ProfileDropdownProps) => {

    const onLogout = () => {
        auth.logout();
    };

    const onProfile = () => {
        props.history.push(Routes.profile);
    };

    return (
        <Dropdown className='profile-dropdown' trigger={['click']}
                  overlay={
                      <Menu>
                          <Menu.Item onClick={onProfile}>
                              Profile
                          </Menu.Item>
                          <Menu.Item onClick={onLogout}>
                              Logout
                          </Menu.Item>
                      </Menu>
                  }>
            <Button>
                <UserAvatar user={user}/> <Typography.Text strong>{user.username}</Typography.Text>
                <Typography.Text type='secondary'>#{user.discriminator}</Typography.Text>
            </Button>
        </Dropdown>
    )
});

export default PHeader;

import * as React from "react";
import GuildAvatar from "./GuildAvatar";
import DiscordGuild from "../stores/types/DiscordGuild";

interface GuildNameProps {
    guild: DiscordGuild;
    select?: boolean;
}

export const GuildName = ({guild, select}: GuildNameProps) => {
    return (
        <span className='guild-name'>
            <GuildAvatar guild={guild} className={select ? 'avatar' : undefined} shape={select ? 'circle': 'square'}/>
            <span>{guild.name}</span>
        </span>
    )
};

export default GuildName;

import * as React from "react";
import {Button, Card, DatePicker, Table, Tooltip} from "antd";
import GuildMember from "../../../stores/models/GuildMember";
import MemberItem from "../../../components/MemberItem";
import {observer} from "mobx-react";
import Guild from "../../../stores/models/Guild";
import UserSelect from "../../../components/UserSelect";
import {SelectValue} from "antd/es/select";
import moment, {Moment} from "moment";
import Loader from "../../../stores/util/Loader";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons'
import Header from "../../../components/Header";

interface BirthdayListProps {
    guild: Guild;
}

interface BirthdaysState {
    member?: string;
    date?: Moment | null;
}

@observer
export class BirthdayList extends React.Component<BirthdayListProps> {
    state: BirthdaysState = {};
    updateLoader = new Loader(500);

    addBirthday = () => {
        const {guild} = this.props;
        const member = guild.members.find(m => m.user.id === this.state.member);
        if (member) {
            member.setBirthday(this.state.date);
            this.updateLoader.load(member.pushBirthdayToServer());
            this.setState({member: undefined, date: undefined});
        }

    };

    removeBirthday = (member: GuildMember) => {
        member.setBirthday(null);
        this.updateLoader.load(member.pushBirthdayToServer());
    };

    changeUser = (value: SelectValue) => {
        this.setState({member: value as string});
    };

    changeDate = (value: Moment | null) => {
        this.setState({date: value});
    };

    static columns = [
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (member: GuildMember | any) => {
                if (member instanceof GuildMember) {
                    return (<MemberItem member={member}/>);
                } else {
                    return member;
                }
            },
            sorter: (a: any, b: any) => {
                if (a.key === "new" || b.key === "new") return 0;
                return a.user?.displayName?.localeCompare(b.user.displayName);
            },
            width: 14
        },
        {
            title: 'Birthday',
            dataIndex: 'birthday',
            key: 'birthday',
            width: 7,
            render: ((b: Moment | null) => {
                if (b instanceof moment) {
                    return b?.format('M/D');
                } else {
                    return b;
                }
            }),
            sorter: (a: any, b: any) => {
                if (a.key === "new" || b.key === "new") return 0;
                if (!a.birthday && !b.birthday) return 0;
                if (a.birthday && !b.birthday) return 1;
                if (b.birthday && !a.birthday) return -1;
                const now = moment().hour(0).minute(0).second(0);
                return a.birthday.year(now.year()).diff(b.birthday.year(now.year())) ?? 0;
            }
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 3
        }
    ];

    render() {
        let {guild} = this.props;

        let birthdays: any = guild.birthdays.map(b => {
            const now = moment().hour(0).minute(0).second(0);
            let birthday = b?.birthday?.year(now.year());
            if (birthday?.isBefore(now)) {
                birthday = birthday.add(1, 'year');
            }
            return {
                key: b.userId,
                user: b,
                birthday,
                action: (
                    <Tooltip title='Remove' mouseEnterDelay={1}>
                        <Button type='primary' danger size='small' shape='circle' icon={<MinusOutlined/>}
                                onClick={() => this.removeBirthday(b)}/>
                    </Tooltip>
                )
            }
        }).sort((a: any, b: any) => {
            if (!a.birthday && !b.birthday) return 0;
            if (a.birthday && !b.birthday) return 1;
            if (b.birthday && !a.birthday) return -1;
            const now = moment();
            return a.birthday.diff(now) - b.birthday.diff(now);
        });

        const buttonEnabled = !!this.state.member && !!this.state.date;

        const footerData = [
            {
                key: 'new',
                user: <UserSelect options={guild.members.filter(m => !m.birthday)} style={{width: '100%'}}
                                  value={this.state.member} onChange={this.changeUser}/>,
                birthday: <DatePicker placeholder='Date' format='M/D' style={{width: '100%'}}
                                      value={this.state.date} onChange={this.changeDate}/>,
                action: (
                    <Tooltip title='Add' mouseEnterDelay={1}>
                        <Button type='primary' shape='circle' icon={<PlusOutlined/>} disabled={!buttonEnabled}
                                onClick={this.addBirthday}/>
                    </Tooltip>
                ),
            }
        ];

        return (
            <Card title={<Header title='Birthdays'
                                 subtitle='Purity will announce the birthdays of members who have their birthday set.'/>}
                  style={{textAlign: "left"}}>
                <Table className='no-footer-padding' columns={BirthdayList.columns} dataSource={birthdays}
                       tableLayout='fixed' loading={this.updateLoader.showLoader}
                       footer={() => {
                           return (
                               <Table columns={BirthdayList.columns} dataSource={footerData} pagination={false}
                                      showHeader={false}
                                      tableLayout='fixed' loading={this.updateLoader.showLoader}/>

                           )
                       }}
                />
            </Card>
        )
    }
}

export default BirthdayList;

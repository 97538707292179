import * as React from "react";
import {Form} from "antd";
import {FormItemProps} from "antd/es/form";

interface SettingItemProps extends FormItemProps {
    inline?: boolean;
    labelWidth?: string | number;
    wrapperWidth?: string | number;
}

export const SettingItem = ({label, children, inline, labelWidth = 24, wrapperWidth = 10, ...props}: SettingItemProps) => {

    if (inline) {
        labelWidth = 8;
    }

    return (
        <Form.Item labelAlign='left' label={label} colon={false} labelCol={{span: labelWidth}} wrapperCol={{span: wrapperWidth}} {...props}>
            {children}
        </Form.Item>
    )
};

export default SettingItem;

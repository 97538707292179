import {action, computed, observable, runInAction} from "mobx";
import GuildSettings from "./GuildSettings";
import GuildMember from "./GuildMember";
import Giveaway from "./Giveaway";
import Rank from "./Rank";
import ReactRole from "./ReactRole";
import SocialTracker from "./SocialTracker";
import api from "../../services/API";
import DiscordChannel from "../types/DiscordChannel";
import CommandStore from "../CommandStore";
import DiscordGuild from "../types/DiscordGuild";
import DiscordRole from "../types/DiscordRole";
import DiscordEmoji from "../types/DiscordEmoji";
import {GuildItem} from "./GuildItem";
import {TicketType} from "./TicketType";
import moment, {Moment} from "moment";

export class Guild implements DiscordGuild {
    id!: string;
    @observable name!: string;
    @observable splash?: string | undefined;
    @observable owner_id!: string;
    @observable icon?: string;
    @observable owner?: boolean;
    @observable permissions?: number;
    @observable voteCount!: number;
    @observable commandCount!: number;
    premium?: number;
    premiumUntil?: Moment;
    @observable settings!: GuildSettings;
    readonly members = observable<GuildMember>([]);
    readonly giveaways = observable<Giveaway>([]);
    readonly ranks = observable<Rank>([]);
    readonly reactRoles = observable<ReactRole>([]);
    readonly twitchTrackers = observable<SocialTracker>([]);
    readonly guildItems = observable<GuildItem>([]);
    readonly ticketTypes = observable<TicketType>([]);
    readonly channels = observable<DiscordChannel>([]);
    readonly roles = observable<DiscordRole>([]);
    readonly emojis = observable<DiscordEmoji>([]);
    @observable commandStore = new CommandStore();

    constructor(json: any) {
        this.update(json);
    }

    @action
    update(json: any) {
        this.id = json.id;
        this.name = json.name;
        this.splash = json.splash;
        this.owner_id = json.owner_id;
        this.icon = json.icon;
        this.owner = json.owner;
        this.permissions = json.permissions;
        this.voteCount = json.voteCount;
        this.commandCount = json.commandCount;
        this.premium = json.premium;
        if (json.premiumUntil) {
            this.premiumUntil = moment(json.premiumUntil);
        }

        if (json.settings) {
            this.updateSettings(json.settings);
        } else {
            this.settings = new GuildSettings({});
        }

        if (json.members) {
            this.members.replace(json.members.map((member: any) => new GuildMember({guildId: this.id, ...member})));
        }

        if (json.giveaways) {
            this.giveaways.replace(json.giveaways.map((giveaway: any) => new Giveaway(giveaway)));
        }

        if (json.ranks) {
            this.ranks.replace(json.ranks.map((rank: any) => new Rank(rank)));
        }

        if (json.reactRoles) {
            this.reactRoles.replace(json.reactRoles.map((reactRole: any) => new ReactRole(reactRole)));
        }

        if (json.twitchTrackers) {
            this.twitchTrackers.replace(json.twitchTrackers.map((socialTracker: any) => new SocialTracker(socialTracker)));
        }

        if (json.guildItems) {
            this.guildItems.replace(json.guildItems.map((guildItem: any) => new GuildItem(guildItem)));
        }

        if (json.ticketTypes) {
            this.ticketTypes.replace(json.ticketTypes.map((ticketType: any) => new TicketType(ticketType)));
        }
    }

    @action
    updateSettings(settings: any) {
        this.settings = new GuildSettings(settings);
        Object.keys(settings).forEach((key: string) => {
            if (settings[key] === false) {
                if (key.startsWith('cmd-')) {
                    const command = this.commandStore.findByName(key.slice(4));
                    if (command) {
                        command.setEnabled(false);
                    }
                } else if (key.startsWith('grp-')) {
                    const group = this.commandStore.groups[key.slice(4)];
                    if (group) {
                        group.setEnabled(false);
                    }
                }
            }
        })
    }

    @computed
    get textChannels() {
        return this.channels.filter(channel => channel.type === 0 || channel.type === 5);
    }

    @computed
    get voiceChannels() {
        return this.channels.filter(channel => channel.type === 2);
    }

    @computed
    get birthdays() {
        return this.members.filter(m => !!m.birthday);
    }

    get isPremium() {
        return this.premium && this.premium >= 1 && this.premiumUntil?.isSameOrAfter(moment());
    }

    findChannelByID(id: string) {
        return this.channels.find(c => c.id === id);
    }

    findRoleByID(id: string) {
        return this.roles.find(r => r.id === id);
    }

    @action
    setChannels(data: DiscordChannel[]) {
        this.channels.replace(data);
    }

    @action
    setRoles(data: DiscordRole[]) {
        this.roles.replace(data);
    }

    @action
    setEmojis(data: DiscordEmoji[]) {
        this.emojis.replace(data);
    }

    @action
    setGuildMembers(data: GuildMember[]) {
        this.members.replace(data);
    }

    @action
    setRanks(ranks: Rank[]) {
        this.ranks.replace(ranks);
    }

    @action
    setGiveaways(giveaways: Giveaway[]) {
        this.giveaways.replace(giveaways);
    }

    @action
    addGiveaway(giveaway: Giveaway) {
        this.giveaways.push(giveaway);
    }

    @action
    removeGiveaway(giveaway: Giveaway) {
        this.giveaways.remove(giveaway);
    }

    @action
    setTwitchTrackers(trackers: SocialTracker[]) {
        this.twitchTrackers.replace(trackers);
    }

    @action
    setReactRoles(reactRoles: ReactRole[]) {
        this.reactRoles.replace(reactRoles);
    }

    @action
    setGuildItems(guildItems: GuildItem[]) {
        this.guildItems.replace(guildItems);
    }

    @action
    setTicketTypes(ticketTypes: TicketType[]) {
        this.ticketTypes.replace(ticketTypes);
    }

    async fetchChannels() {
        const {data} = await api.get<DiscordChannel[]>(`guilds/${this.id}/channels`);
        this.setChannels(data);
    }

    async fetchRoles() {
        const {data} = await api.get<DiscordRole[]>(`guilds/${this.id}/roles`);
        this.setRoles(data);
    }

    async fetchEmojis() {
        const {data} = await api.get<DiscordEmoji[]>(`guilds/${this.id}/emojis`);
        this.setEmojis(data);
    }

    async fetchGuildMembers() {
        const {data} = await api.get<GuildMember[]>(`guilds/${this.id}/members`);
        this.setGuildMembers(data.map(d => new GuildMember({guildId: this.id, ...d})));
    }

    async fetchGiveaways() {
        const {data} = await api.get<Giveaway[]>(`guilds/${this.id}/giveaways`);
        this.setGiveaways(data.map(d => new Giveaway(d)));
    }

    // Tanks

    async fetchRanks() {
        const {data} = await api.get<Rank[]>(`guilds/${this.id}/ranks`);
        this.setRanks(data.map(d => new Rank(d)));
    }

    @action
    async createRank(rank: Rank) {
        rank.setGuildId(this.id);
        await rank.postToServer();
        runInAction(() => this.ranks.push(new Rank(rank)));
    }

    @action
    async deleteRank(rankId: number) {
        const rank = this.ranks.find(r => r.id === rankId);
        if (rank) {
            await rank.deleteFromServer();
            runInAction(() => this.ranks.remove(rank));
        }
    }

    @action
    async updateRank(rank: Rank) {
        await rank.putToServer();
    }

    // Twitch

    async fetchTwitchTrackers() {
        const {data} = await api.get<SocialTracker[]>(`guilds/${this.id}/social-trackers/twitch`);
        this.setTwitchTrackers(data.map(d => new SocialTracker(d)));
    }

    @action
    async createTwitchTracker(tracker: SocialTracker) {
        const {data} = await api.post(`guilds/${this.id}/social-trackers/twitch`, tracker);
        runInAction(() => this.twitchTrackers.push(new SocialTracker(data)));
    }

    @action
    async deleteTwitchTracker(trackerId: number) {
        const tracker = this.twitchTrackers.find(t => t.id === trackerId);
        if (tracker) {
            await api.delete(`guilds/${this.id}/social-trackers/${tracker.id}`);
            runInAction(() => this.twitchTrackers.remove(tracker));
        }
    }

    // React Roles

    async fetchReactRoles() {
        const {data} = await api.get<ReactRole[]>(`guilds/${this.id}/react-roles`);
        this.setReactRoles(data.map(d => new ReactRole(d)));
    }

    @action
    async createReactRole(reactRole: ReactRole) {
        const {data} = await api.post(`guilds/${this.id}/react-roles`, reactRole);
        runInAction(() => this.reactRoles.push(new ReactRole(data)));
    }

    @action
    async deleteReactRole(reactRoleId: number) {
        const reactRole = this.reactRoles.find(t => t.id === reactRoleId);
        if (reactRole) {
            await api.delete(`guilds/${this.id}/react-roles/${reactRole.id}`);
            runInAction(() => this.reactRoles.remove(reactRole));
        }
    }

    @action
    async updateReactRole(reactRole: ReactRole) {
        await api.put(`guilds/${this.id}/react-roles/${reactRole.id}`, reactRole);
    }

    // Guild Items
    async fetchGuildItems() {
        const {data} = await api.get<GuildItem[]>(`guilds/${this.id}/guild-items`);
        this.setGuildItems(data.map(d => new GuildItem(d)));
    }

    @action
    async createGuildItem(guildItem: GuildItem) {
        const {data} = await api.post(`guilds/${this.id}/guild-items`, guildItem);
        runInAction(() => this.guildItems.push(new GuildItem(data)));
    }

    @action
    async deleteGuildItem(guildItemId: number) {
        const guildItem = this.guildItems.find(t => t.id === guildItemId);
        if (guildItem) {
            await api.delete(`guilds/${this.id}/guild-items/${guildItem.id}`);
            runInAction(() => this.guildItems.remove(guildItem));
        }
    }

    // Ticket Types
    async fetchTicketTypes() {
        const {data} = await api.get<TicketType[]>(`guilds/${this.id}/ticket-types`);
        this.setTicketTypes(data.map(d => new TicketType(d)));
    }

    @action
    async createTicketType(ticketType: TicketType) {
        const {data} = await api.post(`guilds/${this.id}/ticket-types`, ticketType);
        runInAction(() => this.ticketTypes.push(new TicketType(data)));
    }

    @action
    async deleteTicketType(ticketTypeId: number) {
        const ticketTypes = this.ticketTypes.find(t => t.id === ticketTypeId);
        if (ticketTypes) {
            await api.delete(`guilds/${this.id}/ticket-types/${ticketTypes.id}`);
            runInAction(() => this.ticketTypes.remove(ticketTypes));
        }
    }

    async pushSettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings);
    }

    async pushBasicSettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings.basicSettings);
    }

    async pushWelcomeSettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings.welcomeSettings);
    }

    async pushBirthdaySettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings.birthdaySettings);
    }

    async pushLevelSettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings.levelSettings);
    }

    async pushEconomySettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings.economySettings);
    }

    async pushModerationSettingsToServer() {
        await api.put(`guilds/${this.id}/settings`, this.settings.moderationSettings);
    }
}

export default Guild;

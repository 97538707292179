import * as React from "react";
import {Select} from "antd";
import {SelectProps} from "antd/es/select";
import DiscordChannel from "../stores/types/DiscordChannel";

interface ChannelSelectProps extends Omit<SelectProps<string>, 'value'|'options'> {
    options: DiscordChannel[];
    value?: string | null;
}

export const ChannelSelect = ({options, value, ...props}: ChannelSelectProps) => {

    const filterOption = (value: string, option: any) => {
        const channel = options.find(m => m.id === option.key);
        return !!(channel &&
            ((channel.name && channel.name?.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                || channel.id.toLowerCase().indexOf(value.toLowerCase()) >= 0));
    };

    return (
        <Select className='prefix-select' showSearch allowClear placeholder='Channel' suffixIcon={'#'}
                filterOption={filterOption} value={value ?? undefined} {...props}>
            {options.map(channel => (
                <Select.Option key={channel.id} value={channel.id}>
                    {channel.name}
                </Select.Option>
            ))}
        </Select>
    )
};

export default ChannelSelect;

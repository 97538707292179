import * as React from "react";
import {Dropdown, Layout, Menu} from "antd";
import Icon, {DoubleLeftOutlined, DoubleRightOutlined, SettingFilled} from '@ant-design/icons';
import {MdCake, MdTune} from "react-icons/md";
import {FaLaugh, FaMedal, FaMoneyBillWave, FaShieldAlt, FaStore, FaTicketAlt, FaTrello, FaTwitch} from "react-icons/fa";
import {GiUpgrade} from "react-icons/gi";
import Routes, {guildRoute} from "../../Routes";
import {Link, match, matchPath, RouteComponentProps} from "react-router-dom";
import {observer} from "mobx-react";
import stores, {StoreContext} from "../../stores/Stores";
import GuildName from "../../components/GuildName";
import Loader from "../../stores/util/Loader";
import GuildAvatar from "../../components/GuildAvatar";
import GoogleAd from "../../components/GoogleAd";
import GuildSelect from "../../components/GuildSelect";

const {Sider} = Layout;

type IState = {
    collapsed: boolean;
    mobile: boolean;
}

@observer
export class MainMenu extends React.Component<RouteComponentProps, IState> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    state: IState = {
        collapsed: false,
        mobile: false
    };

    componentDidMount(): void {
        // detect screen width to adjust side menu
        this.setMobile();
        window.addEventListener("resize", this.setMobile);
        this.loader.load(this.fetchGuilds());
    }

    async fetchGuilds() {
        await stores.domainStore.fetchGuilds();
        const match = matchPath<{ guildId: string }>(window.location.pathname, Routes.guild);
        let guildId;
        if (match && match.params.guildId) {
            guildId = match.params.guildId;
        } else {
            guildId = stores.domainStore.guilds[0]?.id;
        }
        if (guildId) {
            stores.domainStore.setGuildById(guildId);
            this.changeGuild(guildId);
        }
    }

    setMobile = () => {
        const mobile = window.innerWidth <= 768;
        this.setState({mobile, collapsed: mobile});
    };

    toggleCollapsed = () => {
        const {collapsed} = this.state;
        this.setState({collapsed: !collapsed});
    };

    collapse = () => {
        this.setState({collapsed: true});
    };

    changeGuild = (value: string) => {
        this.context.domainStore.setGuildById(value);
        this.context.domainStore.fetchGuild(value);
        const match = this.getMatch();
        let route = match?.path ?? Routes.guild;
        if (value && route === Routes.home) {
            route = Routes.guild;
        }

        this.props.history.push(guildRoute(route, value));
        if (this.state.mobile) {
            this.collapse();
        }
    };

    getMatch(): match | null {
        let match = null;
        Object.values(Routes).forEach(route => {
            const m = matchPath(this.props.location.pathname, route);
            if (m) {
                match = m;
            }
        });

        return match;
    }

    render() {
        const {guilds, guild} = this.context.domainStore;
        const guildId = guild ? guild.id : undefined;
        const mobile = this.state.mobile;
        const trigger = mobile ? undefined : null;
        const collapsedWidth = mobile ? 0 : undefined;
        const match = this.getMatch();
        const route = match?.path ?? '';

        return (
            <Sider className='side-menu' width={250} trigger={trigger}
                   zeroWidthTriggerStyle={{top: 68}}
                   collapsible collapsedWidth={collapsedWidth}
                   collapsed={this.state.collapsed} onCollapse={this.toggleCollapsed}>
                <Menu mode="inline" theme="dark" selectedKeys={[route]} style={{textAlign: 'left', minHeight: '100vh'}}
                      onSelect={({key}) => {
                          if (mobile && key !== 'server-select') {
                              this.collapse();
                          }
                      }}>
                    <div className='kt-aside__brand'>
                        {!this.state.collapsed &&
                        <div className='kt-aside__brand-logo'>
                          <img className='logo' src={require('../../resources/img/purity_logo.png')} alt="Purity Logo"/>
                        </div>
                        }
                        <div className='kt-aside__brand-tools'>
                            <button className="kt-aside__brand-aside-toggler" id="kt_aside_toggler"
                                    onClick={this.toggleCollapsed}>
                                {this.state.collapsed ?
                                    <DoubleRightOutlined/>
                                    :
                                    <DoubleLeftOutlined/>
                                }
                            </button>
                        </div>
                    </div>
                    <Menu.Item key={'server-select'} className='server-select'>
                        {!this.state.collapsed ?
                            <GuildSelect options={guilds} placeholder='Select a Server' style={{width: '100%'}}
                                         allowClear={false}
                                         value={guild?.id} onChange={this.changeGuild} size='large'/>
                            :
                            <Dropdown trigger={['click']} placement="bottomRight"
                                      overlay={
                                          <Menu>
                                              {guilds.map((guild: any) => {
                                                  return (
                                                      <Menu.Item key={guild.id}
                                                                 onClick={() => this.changeGuild(guild.id)}>
                                                          <GuildName guild={guild} select/>
                                                      </Menu.Item>
                                                  )
                                              })}
                                          </Menu>
                                      }>
                                <div style={{textAlign: "center"}}>
                                    {guild && <GuildAvatar guild={guild}/>}
                                </div>
                            </Dropdown>
                        }
                    </Menu.Item>
                    {/*<Menu.Item key={Routes.dashboard}>
                        <Link to={guildRoute(Routes.dashboard, guildId)}>
                            <Icon type="area-chart"/><span>Dashboard - Overview</span>
                        </Link>
                    </Menu.Item>*/}
                    <Menu.ItemGroup title="Configuration">
                        <Menu.Item key={Routes.general}>
                            <Link to={guildRoute(Routes.general, guildId)}>
                                <SettingFilled/><span>General Configuration</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.economy}>
                            <Link to={guildRoute(Routes.economy, guildId)}>
                                <Icon component={FaMoneyBillWave}/><span>Economy</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.leveling}>
                            <Link to={guildRoute(Routes.leveling, guildId)}>
                                <Icon component={GiUpgrade}/><span>Leveling System</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.moderation}>
                            <Link to={guildRoute(Routes.moderation, guildId)}>
                                <Icon component={FaShieldAlt}/><span>Moderation</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.commands}>
                            <Link to={guildRoute(Routes.commands, guildId)}>
                                <Icon component={MdTune}/><span>Command Toggle</span>
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup title="Modules">
                        <Menu.Item key={Routes.ranks}>
                            <Link to={guildRoute(Routes.ranks, guildId)}>
                                <Icon component={FaMedal}/><span>Ranks</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.reactRoles}>
                            <Link to={guildRoute(Routes.reactRoles, guildId)}>
                                <Icon component={FaLaugh}/><span>React Roles</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={Routes.birthdays}>
                            <Link to={guildRoute(Routes.birthdays, guildId)}>
                                <Icon component={MdCake}/><span>Birthdays</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.serverShop}>
                            <Link to={guildRoute(Routes.serverShop, guildId)}>
                                <Icon component={FaStore}/><span>Server Shop</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.twitch}>
                            <Link to={guildRoute(Routes.twitch, guildId)}>
                                <Icon component={FaTwitch}/><span>Twitch</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={Routes.giveaways} disabled>
                            <Link to={guildRoute(Routes.giveaways, guildId)}>
                                <Icon component={FaTicketAlt}/><span>Giveaways (Coming Soon)</span>
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.ItemGroup title='Premium'>
                        <Menu.Item key={Routes.tickets}>
                            <Link to={guildRoute(Routes.tickets, guildId)}>
                                <Icon component={FaTrello}/><span>Tickets</span>
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </Menu>

                <GoogleAd className='sidebar-ad' type="rectangle"/>

            </Sider>
        )
    }
}

export default MainMenu;

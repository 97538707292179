import * as React from "react";
import {Card, Typography} from "antd";
import Subscriptions from "./scenes/Subscriptions";
import CheckoutModal from "./scenes/CheckoutModal";

interface PremiumState {
    modalOpen: boolean,
    plan: string
}

export class Premium extends React.Component<any, PremiumState> {
    state = {
        modalOpen: false,
        plan: 'plan_Ge0vMoODJZkbt7'
    };

    openModal = (plan: string) => {
        this.setState({modalOpen: true, plan});
    };

    closeModal = () => {
        this.setState({modalOpen: false});
    };

    render() {
        return (
            <div className='premium-page'>
                <Subscriptions/>
                <div style={{marginTop: 12}}>
                    <Typography.Title level={2}>Choose a Plan</Typography.Title>
                    <Card className='premium-card' hoverable onClick={() => this.openModal('plan_Ge0vMoODJZkbt7')}>
                        <Typography.Title level={2}>1 Scoop</Typography.Title>
                        {/*<Typography.Text type='secondary' strong style={{fontSize: '1.5em'}}>Premium for 1*/}
                        {/*    server</Typography.Text>*/}
                        <div>
                            <Typography.Text strong style={{fontSize: '4em', color: '#5F68CD'}}>$5.00<Typography.Text
                                type='secondary' style={{fontSize: '.5em'}}>/month</Typography.Text></Typography.Text>
                            <Typography.Paragraph style={{textAlign: 'left', marginTop: 10}}>
                                Features:
                                <ul>
                                    <li>Custom !profile styling</li>
                                    <li>Ticket System</li>
                                    <li>Lockdown System</li>
                                    <li>Voice Chat Roles</li>
                                    <li>10 Twitch channel trackers</li>
                                    <li>Premium Music for better reliability</li>
                                </ul>
                            </Typography.Paragraph>
                        </div>
                    </Card>
                </div>

                <CheckoutModal plan={this.state.plan} visible={this.state.modalOpen} onCancel={this.closeModal}/>
            </div>
        )
    }
}

export default Premium;

import * as React from "react";
import {Button, Modal, Result} from "antd";
import {ModalProps} from "antd/es/modal";
import api from "../../../services/API";
import Loader from "../../../stores/util/Loader";
import {StoreContext} from "../../../stores/Stores";
import {RouteComponentProps, withRouter} from "react-router";
import {observer} from "mobx-react";
import Subscription from "../models/Subscription";
import Checkout from "./Checkout";
import Routes, {guildRoute} from "../../../Routes";
import config from "../../../app.config";

interface CheckoutModalProps extends ModalProps {
    plan: string
}

interface CheckoutModalState {
    subscription?: Subscription;
    complete?: boolean;
}

export const CheckoutModal = withRouter(observer(class extends React.Component<CheckoutModalProps & RouteComponentProps, CheckoutModalState> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    state: CheckoutModalState = {};

    submitSubscription = async () => {
        const {subscription} = this.state;
        if (subscription) {
            try {
                await this.loader.load(api.post('premium/subscriptions', {
                    payment_method: subscription.paymentMethod?.id,
                    billing_details: subscription.billingDetails,
                    guildId: subscription.guildId,
                    plan: subscription.plan
                }));
                this.setState({complete: true});
            } catch (e) {

            }
        }
    };

    handleSubscriptionChange = (subscription: Subscription) => {
        this.setState({subscription});
    };

    handleComplete = () => {
        if (!this.state.complete) {
            this.setState({complete: true});
        }
    };

    onClose = () => {
        // Wiped data on close
        this.setState({subscription: undefined, complete: false});
    };

    goToDashboard = () => {
        this.props.history.push(guildRoute(Routes.guild, this.context.domainStore.guild?.id));
    };

    render() {
        const {subscription} = this.state;
        const disabled = !(subscription?.paymentMethod && subscription.guildId && subscription.plan
            && subscription.paymentMethod && subscription.paymentMethod?.billing_details?.name
            && subscription.billingDetails && subscription.billingDetails.email && subscription.billingDetails.name);

        return (
            <Modal className='payment-modal' title='Payment' mask destroyOnClose afterClose={this.onClose}
                   footer={
                       !this.state.complete && !this.loader.hasError &&
                       <Button type='primary' disabled={disabled} onClick={this.submitSubscription}
                               loading={this.loader.isLoading}>Pay</Button>
                   }
                   centered {...this.props}>
                {this.loader.hasError &&
                <Result status='500' title='Internal Server Error'
                        subTitle={
                            <div>An error occurred while trying to create your subscription. Please contact someone in
                                the&nbsp;
                                <a href={config.supportDiscord} target='_blank'>support server</a> or email us at&nbsp;
                                <a href={`mailto:${config.supportEmail}?Subject=Cancel%20Subscription`}
                                   target='_blank'>{config.supportEmail}</a>.
                            </div>
                        }
                        extra={<Button type='primary' onClick={this.goToDashboard}>Go to Dashboard</Button>}
                />
                }

                {!this.loader.hasError &&
                <Checkout plan={this.props.plan} onSubscriptionChange={this.handleSubscriptionChange}
                          complete={this.state.complete} onComplete={this.handleComplete}/>
                }
            </Modal>
        )
    }
}));

export default CheckoutModal;

import * as React from "react";
import {ChangeEventHandler, FormEvent} from "react";
import {observer} from "mobx-react";
import {Button, Card, DatePicker, Form, Input, InputNumber, message} from "antd";
import SettingItem from "../../../components/SettingItem";
import ChannelSelect from "../../../components/ChannelSelect";
import RoleSelect from "../../../components/RoleSelect";
import {Moment} from "moment";
import Giveaway from "../../../stores/models/Giveaway";
import {GuildComponentProps} from "../../../types/GuildComponentProps";

@observer
export class AddGiveaway extends React.Component<GuildComponentProps> {

    giveawayToAdd = new Giveaway();

    submit = async () => {
        try {
            const {guild} = this.props;
            this.giveawayToAdd.setGuildId(guild.id);
            await this.giveawayToAdd.create();
            guild.addGiveaway(this.giveawayToAdd!);
            message.success('Giveaway created!');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    changePrize: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        this.giveawayToAdd?.setPrize(target.value);
    };

    changeTime = (time: Moment | null) => {
        if (time) {
            this.giveawayToAdd?.setGiveawayTime(time);
        }
    };

    changeChannel = (channelId: string) => {
        this.giveawayToAdd?.setChannelId(channelId);
    };

    changeWinners = (num?: number | string) => {
        if (typeof num === "string") {
            num = parseInt(num);
        }
        this.giveawayToAdd?.setNumWinners(num);
    };

    changeRole = (roleId: string) => {
        this.giveawayToAdd?.setRoleId(roleId);
    };

    changeLevel = (num?: number | string) => {
        if (typeof num === "string") {
            num = parseInt(num);
        }
        this.giveawayToAdd?.setLevelRequirement(num);
    };

    render() {
        const {textChannels, roles} = this.props.guild;
        const {prize, giveawayTime, channelId, numWinners, roleId, levelRequirement} = this.giveawayToAdd;

        return (
            <Card title="Add a Giveaway" style={{textAlign: "left"}}>
                <Form labelAlign='left' onFinish={this.submit}>
                    <SettingItem label='Prize' required>
                        <Input required value={prize} onChange={this.changePrize}/>
                    </SettingItem>
                    <SettingItem label='End Time' required>
                        <DatePicker showTime value={giveawayTime} onChange={this.changeTime}/>
                    </SettingItem>
                    <SettingItem label='Channel' required>
                        <ChannelSelect options={textChannels}
                                       value={channelId} onChange={this.changeChannel}/>
                    </SettingItem>
                    <SettingItem label='Number of Winners'>
                        <InputNumber  required defaultValue={1} min={1}
                                     value={numWinners} onChange={this.changeWinners}/>
                    </SettingItem>
                    <SettingItem label='Role Restriction'>
                        <RoleSelect options={roles}
                                    value={roleId} onChange={this.changeRole}/>
                    </SettingItem>
                    <SettingItem label='Level Restriction'>
                        <InputNumber value={levelRequirement} onChange={this.changeLevel}/>
                    </SettingItem>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Submit</Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

export default AddGiveaway;

export function fieldSorter<T extends {id?: any}>(field: keyof T) {
    return (a: T, b: T) => {
        if (!a.id || !b.id) return 0;
        if (!a[field] && !b[field]) return 0;
        if (a[field] && !b[field]) return 1;
        if (b[field] && !a[field]) return -1;
        if (typeof a[field] === "string") {
            return (a[field] as unknown as string)!.localeCompare(b[field] as unknown as string);
        } else if (typeof a[field] === "number") {
            return (a[field] as unknown as number) - (b[field] as unknown as number);
        }
        return 0;
    }
}

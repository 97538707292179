import {action, observable} from "mobx";

export class SocialTracker {
    id?: number;
    guildId?: string;
    @observable name?: string;
    @observable channelId?: string; //Text channel
    @observable service?: string;
    lastSeen!: Date;

    constructor(json?: SocialTracker) {
        if (json) {
            this.update(json);
        }
    }

    @action
    update(json: any) {
        this.id = json.id;
        this.guildId = json.guildId;
        this.name = json.name;
        this.channelId = json.channelId;
        this.service = json.service;
        this.lastSeen = json.lastSeen;
    }

    @action setName(name: string) {
        this.name = name;
    }

    @action setChannelId(channelId: string) {
        this.channelId = channelId;
    }

    @action setService(service: string) {
        this.service = service;
    }
}

export default SocialTracker;

import React, {Component} from 'react';
import './App.css';
import './styles.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Routes from "./Routes";
import Login from "./scenes/Login";
import {observer} from "mobx-react";
import stores, {StoreContext} from "./stores/Stores";
import Loader from "./stores/util/Loader";
import LoadingWrapper from "./components/LoadingWrapper";
import PrivateRoute from "./components/PrivateRoute";
import MainMenu from "./scenes/Menu/MainMenu";
import {Alert, Layout} from "antd";
import PHeader from "./scenes/PHeader";
import GuildWrapper from "./components/GuildWrapper";
import GoogleAd from "./components/GoogleAd";
import Premium from "./scenes/Premium/Premium";
import config from "./app.config";
import {StripeProvider} from "react-stripe-elements";
import Profile from "./scenes/Profile/Profile";
import Cookies from "js-cookie";
import Error from "./components/Error";

@observer
class App extends Component {
    loader = new Loader();

    render() {
        const acceptedCookies = Cookies.get('accepted-cookies') === 'true';
        return (
            <StoreContext.Provider value={stores}>
                <StripeProvider apiKey={config.stripe_key}>
                    <LoadingWrapper loader={this.loader}>
                        <Router>
                            {/*Updates Google Analytics path on route change*/}
                            <Route path='/' render={({location}) => {
                                if (typeof window.gtag === 'function') {
                                    window.gtag('config', 'UA-156920116-1', {'page_path': location.pathname});
                                }
                                return null;
                            }}/>
                            <Layout className="App" hasSider>
                                    <PrivateRoute redirect={false} component={MainMenu}/>
                                    <Layout>
                                        <PrivateRoute redirect={false} component={PHeader}/>
                                        <Layout.Content style={{padding: 20}}>
                                            {stores.domainStore.error ?
                                                <Error error={stores.domainStore.error}/>
                                                :
                                                <>
                                                    <GoogleAd className='banner-ad' type='horizontal'/>
                                                    <Switch>
                                                        <Route exact path={Routes.login} component={Login}/>
                                                        <PrivateRoute exact path={Routes.profile} component={Profile}/>
                                                        <PrivateRoute exact path={Routes.premium} component={Premium}/>
                                                        <PrivateRoute
                                                            path={[Routes.home, Routes.dashboard, Routes.guild]}
                                                            component={GuildWrapper}/>
                                                    </Switch>
                                                </>
                                            }
                                        </Layout.Content>
                                    </Layout>

                            </Layout>
                        </Router>
                        {!acceptedCookies &&
                        <Alert className='cookie-notice' type='info' banner closable closeText='Accept'
                               onClose={() => Cookies.set('accepted-cookies', 'true')}
                               message={
                                   <div>We use cookies to give you the best experience.
                                       For more details, check out our <a href='http://puritybot.com'>Cookie Policy</a>.
                                   </div>
                               }/>
                        }
                    </LoadingWrapper>
                </StripeProvider>
            </StoreContext.Provider>
        );
    }
}

export default App;

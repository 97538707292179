import {DomainStore} from "./DomainStore";
import UserStore from "./UserStore";
import React from "react";
import {action, observable} from "mobx";

class Stores {
    @observable userStore = new UserStore();
    @observable domainStore = new DomainStore();

    @action
    clear() {
        this.userStore = new UserStore();
        this.domainStore = new DomainStore();
    }
}

export const stores = new Stores();
export default stores;

export const StoreContext = React.createContext(stores);

import * as React from "react";
import {Button, Card, Empty, message, Modal, Select, Skeleton, Space, Tooltip} from "antd";
import Loader from "../../stores/util/Loader";
import {observer} from "mobx-react";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import {GuildItem} from "../../stores/models/GuildItem";
import {fieldSorter} from "../../util/fieldSorter";
import Header from "../../components/Header";
import ShopItem from "./scenes/ShopItem";
import GuildItemForm from "./scenes/GuildItemForm";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";

interface ServerShopState {
    modalOpen: boolean;
    sortBy: keyof GuildItem;
    reverseSort: boolean;
}

@observer
export class ServerShop extends React.Component<GuildComponentProps, ServerShopState> {

    loader = new Loader();
    guildItemToAdd = new GuildItem({available: true});
    state: ServerShopState = {
        modalOpen: false,
        sortBy: 'id',
        reverseSort: false
    };

    componentDidMount() {
        this.loader.load(this.fetchOptions());
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.loader.load(this.fetchOptions());
        }
    }

    async fetchOptions() {
        const {guild} = this.props;

        if (!guild.roles || guild.roles.length === 0) {
            await guild.fetchRoles();
        }

        if (!guild.channels || guild.channels.length === 0) {
            await guild.fetchChannels();
        }

        if (!guild.emojis || guild.emojis.length === 0) {
            await guild.fetchEmojis();
        }

        await guild.fetchGuildItems();
    }

    openModal = () => this.setState({modalOpen: true});

    closeModal = () => this.setState({modalOpen: false});

    changeSortBy = (value: keyof GuildItem) => {
        this.setState({sortBy: value});
        this.sort(value, this.state.reverseSort);
    };

    changeSortOrder = () => {
        this.sort(this.state.sortBy, !this.state.reverseSort);
        this.setState({reverseSort: !this.state.reverseSort});
    };

    sort(sortBy: keyof GuildItem, reversed: boolean) {
        this.props.guild.setGuildItems(this.props.guild.guildItems.sort(fieldSorter<GuildItem>(sortBy)));
        if (reversed) {
            this.props.guild.setGuildItems(this.props.guild.guildItems.reverse());
        }
    }

    submitGuildItem = async () => {
        try {
            await this.loader.load(this.props.guild.createGuildItem(this.guildItemToAdd));
            this.guildItemToAdd.update({available: true}); // clear the item to add
            message.success('Added item!');
            this.setState({modalOpen: false});
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    render() {
        const {guild} = this.props;

        return (
            <Card title={<Header title='Server Shop'
                                 subtitle='Add items to your shop and users will be able to buy them.'/>}
                  style={{textAlign: "left"}}
                  extra={[<Button type='primary' onClick={this.openModal}>Add Item</Button>]}>
                <Select style={{width: 200}} value={this.state.sortBy} onChange={this.changeSortBy}
                        optionLabelProp={'label'}>
                    <Select.Option key='id' value='id' label='Sort By: ID'>ID</Select.Option>
                    <Select.Option key='reqCurrency' value='reqCurrency' label='Sort By: Price'>Price</Select.Option>
                    <Select.Option key='qty' value='qty' label='Sort By: Quantity'>Quantity</Select.Option>
                    <Select.Option key='limitForUser' value='limitForUser' label='Sort By: User Limit'>User
                        Limit</Select.Option>
                    <Select.Option key='reqLevel' value='reqLevel' label='Sort By: Required Level'>Required
                        Level</Select.Option>
                </Select>
                <Tooltip title={'Order: ' + (this.state.reverseSort ? 'Descending' : 'Ascending')}>
                    <Button onClick={this.changeSortOrder}>{this.state.reverseSort ? <CaretDownOutlined/> :
                        <CaretUpOutlined/>}</Button>
                </Tooltip>

                {!this.loader.isLoading && guild.guildItems.length === 0 &&
                <Empty description="You haven't added any items to your shop yet."/>
                }

                {this.loader.showLoader &&
                <Skeleton/>
                }
                <Space direction='vertical' style={{width: '100%'}}>
                    {guild.guildItems.map(guildItem => <ShopItem key={guildItem.id} item={guildItem} guild={guild}/>)}
                </Space>
                <Modal className='guild-item-modal' title='Create a Server Shop Item' destroyOnClose
                       visible={this.state.modalOpen} onCancel={this.closeModal}
                       okText='Submit' onOk={this.submitGuildItem}
                       okButtonProps={{disabled: !this.guildItemToAdd.isValid}}>
                    <GuildItemForm item={this.guildItemToAdd} guild={guild}/>
                </Modal>
            </Card>
        )
    }
}

export default ServerShop;

import * as React from "react";
import {Card, message, Tooltip} from "antd";
import {TicketType} from "../../../stores/models/TicketType";
import {observer} from "mobx-react";
import {CheckOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import api from "../../../services/API";
import Loader from "../../../stores/util/Loader";
import Guild from "../../../stores/models/Guild";
import TicketTypeForm from "./TicketTypeForm";

interface TicketTypeProps {
    ticketType: TicketType;
    guild: Guild;
}

interface TicketTypeState {
    edit: boolean;
}

@observer
export class TicketTypeItem extends React.Component<TicketTypeProps, TicketTypeState> {
    loader = new Loader();
    state = {
        edit: false
    };

    toggleEdit = () => {
        this.setState({edit: !this.state.edit});
    };

    deleteTicketType = async () => {
        if (this.props.ticketType.id) {
            try {
                await this.props.guild.deleteTicketType(this.props.ticketType.id);
                message.success('Deleted ticket type');
            } catch (e) {
                message.error('Something went wrong');
            }
        }
    };

    submitChanges = async () => {
        const {guildId, id} = this.props.ticketType;
        if (this.props.ticketType) {
            try {
                await this.loader.load(api.put(`guilds/${guildId}/ticket-types/${id}`, this.props.ticketType));
                message.success('Ticket Type updated');
            } catch (e) {
                message.error('Something went wrong');
            }
        }
    };

    render() {
        const {id, topic, questions} = this.props.ticketType;
        const editIcon = this.state.edit ? <CheckOutlined onClick={() => {
                this.submitChanges();
                this.toggleEdit();
            }}/> :
            <EditOutlined onClick={this.toggleEdit}/>;
        return (
            <Card title={`${id}: ${topic}`} type='inner'
                  actions={[
                      <Tooltip title={this.state.edit ? 'Save' : 'Edit'} mouseEnterDelay={1}>
                          {editIcon}
                      </Tooltip>,
                      <Tooltip title='Delete' mouseEnterDelay={0.5}>
                          <DeleteOutlined onClick={this.deleteTicketType}/>
                      </Tooltip>
                  ]}>
                {(!questions || questions.length === 0) &&
                <div>There are no questions of this topic.</div>
                }

                {this.state.edit ?
                    <TicketTypeForm ticketType={this.props.ticketType} guild={this.props.guild}/>
                    :
                    <div>
                        <label>Questions</label>
                        <ol>
                        {questions.map((question, i) => {
                            return (<li key={i}>{question}</li>)
                        })}
                        </ol>
                    </div>
                }
            </Card>
        )
    }
}

export default TicketTypeItem;

import * as React from "react";
import {Col, InputNumber, Row} from "antd";
import {parseNumber} from "../util/parseNumber";

export interface RangeValue {
    min: number;
    max: number;
}

interface RangeProps {
    value: RangeValue | string | number | undefined;
    min?: number;
    max?: number;
    onChange: (value: RangeValue) => void;
}

const parseRange = (range: string | number) => {
    if (typeof range === 'string') {
        const dashIndex = range.indexOf('-');
        if (dashIndex >= 0) {
            return {
                min: parseInt(range.substring(0, dashIndex)),
                max: parseInt(range.substring(dashIndex + 1))
            }
        }
    }
    const val = parseNumber(range) ?? 0;
    return {min: val, max: val}

}

export const RangeInput = (props: RangeProps) => {
    let min = props.min ?? 0, max = props.max ?? 0;
    if (typeof props.value === 'string' || typeof props.value === 'number') {
        const range = parseRange(props.value);
        min = range.min;
        max = range.max;
    } else if (props.value != null) {
        min = props.value.min;
        max = props.value.max;
    }

    const handleChangeMin = (value?: string | number) => {
        value = parseNumber(value);
        if (value != null) {
            props.onChange({
                min: value,
                max: value <= max ? max : value
            })
        }
    }

    const handleChangeMax = (value?: string | number) => {
        value = parseNumber(value);
        if (value != null && value >= min) {
            props.onChange({
                min: min,
                max: value
            })
        }
    }

    return (
        <Row>
            <Col>
                <InputNumber min={props.min} max={props.max} value={min} onChange={handleChangeMin}/>
            </Col>
            <Col span={2} style={{textAlign: 'center'}}>
                -
            </Col>
            <Col>
                <InputNumber min={props.min} max={props.max} value={max} onChange={handleChangeMax}/>
            </Col>
        </Row>
    )
};

export default RangeInput;

import * as React from "react";
import {ChangeEventHandler} from "react";
import {Button, Card, Form, Input, message, Switch} from "antd";
import SettingItem from "../../../components/SettingItem";
import Loader from "../../../stores/util/Loader";
import {observer} from "mobx-react";
import Guild from "../../../stores/models/Guild";
import Help from "../../../components/Help";

interface BasicSettingsProps {
    guild: Guild
}

@observer
export class BasicSettings extends React.Component<BasicSettingsProps> {
    loader = new Loader();

    submit = async () => {
        try {
            await this.loader.load(this.props.guild.pushBasicSettingsToServer());
            message.success('Settings updated');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    changePrefix: ChangeEventHandler<HTMLInputElement> = ({target: {value}}) => {
        this.props.guild.settings.setPrefix(value)
    };

    changeAdminOnly = (checked: boolean) => {
        this.props.guild.settings.setAdminOnly(checked);
    };

    changePetSystem = (checked: boolean) => {
        this.props.guild.settings.setPetSystem(checked);
    };

    render() {
        const {guild} = this.props;
        const {prefix, petSystem, adminOnly} = guild.settings.basicSettings;

        return (
            <Card title={'Basic Server Settings'} style={{textAlign: "left"}}>
                <Form labelAlign='left'>
                    <SettingItem label={<Help label='Bot Prefix' help='Commands must start with this prefix.'/>}>
                        <Input value={prefix} onChange={this.changePrefix}/>
                    </SettingItem>
                    <SettingItem label={<Help label='Admin Only' help='Makes it so only admins can use the bot.'/>} inline>
                        <Switch checked={adminOnly} onChange={this.changeAdminOnly}/>
                    </SettingItem>
                    <SettingItem label={<Help label='Pet System' help='Members can own and take care of pets.'/>} inline>
                        <Switch checked={petSystem} onChange={this.changePetSystem}/>
                    </SettingItem>
                </Form>
                <Button type='primary' onClick={this.submit} loading={this.loader.isLoading}>Save</Button>
            </Card>
        )
    }
};

export default BasicSettings;

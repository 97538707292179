import * as React from "react";
import {ChangeEvent} from "react";
import {Card, Input, message} from "antd";
import Loader from "../../stores/util/Loader";
import {observer, Observer} from "mobx-react";
import RoleSelect from "../../components/RoleSelect";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import ReactRole from "../../stores/models/ReactRole";
import ChannelSelect from "../../components/ChannelSelect";
import Emoji from "../../components/Emoji";
import EmojiSelect from "../../components/EmojiSelect";
import Role from "../../components/Role";
import Header from "../../components/Header";
import EditableTable from "../../components/EditableTable";

@observer
export class ReactRoles extends React.Component<GuildComponentProps> {

    loader = new Loader();
    reactRoleToAdd = new ReactRole({id: 'new', editing: true, isNew: true});

    componentDidMount() {
        this.loader.load(this.fetchOptions());
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.loader.load(this.fetchOptions());
        }
    }

    async fetchOptions() {
        const {guild} = this.props;

        if (!guild.roles || guild.roles.length === 0) {
            await guild.fetchRoles();
        }

        if (!guild.channels || guild.channels.length === 0) {
            await guild.fetchChannels();
        }

        if (!guild.emojis || guild.emojis.length === 0) {
            await guild.fetchEmojis();
        }

        await guild.fetchReactRoles();
    }

    onChangeRoleId(reactRole: ReactRole) {
        return (roleId: string) => {
            reactRole.setRoleId(roleId);
        };
    }

    onChangeEmoji(reactRole: ReactRole) {
        return (value: string) => {
            console.debug(value);
            // let {value} = e.target;
            /*const emoji = this.props.guild.emojis.find(e => e.name === value);
            if (emoji) {
                value = emoji.id;
            }*/
            reactRole.setEmoji(value);
        };
    }

    onChangeChannelId(reactRole: ReactRole) {
        return (channelId: string) => {
            reactRole.setChannelId(channelId);
        };
    }

    onChangeMessageId(reactRole: ReactRole) {
        return (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            try {
                BigInt(value);
                reactRole.setMessageId(value.trim());
            } catch (e) {
            }
        };
    }

    updateReactRole = async (reactRole: ReactRole) => {
        try {
            await this.props.guild.updateReactRole(reactRole);
            message.success('Updated react role');
        } catch (e) {
            message.error('Something went wrong');
            throw e;
        }
    }

    deleteReactRole = async (reactRole: ReactRole) => {
        try {
            await this.props.guild.deleteReactRole(reactRole.id);
            message.success('Deleted react role');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    submitReactRole = async () => {
        try {
            const dto: any = Object.assign({}, this.reactRoleToAdd);
            dto.id = undefined;
            await this.loader.load(this.props.guild.createReactRole(dto));
            this.reactRoleToAdd.update({}); // clear the react role to add
            message.success('Added react role!')
        } catch (e) {
            if (e.response && e.response.status === 400) {
                message.error('Invalid message ID. Make sure you are using the channel that the message is in. Also make sure that Purity has the "Read Messages" and "Read Message History" permissions.', 10);
            } else {
                message.error('Something went wrong');
            }
        }
    };

    static fieldSorter(field: keyof ReactRole) {
        return (a: ReactRole, b: ReactRole) => {
            if (a.isNew || b.isNew) return 0;
            if (!a[field] && !b[field]) return 0;
            if (a[field] && !b[field]) return 1;
            if (b[field] && !a[field]) return -1;
            if (typeof a[field] === "string") {
                return (a[field] as string)!.localeCompare(b[field] as string);
            } else if (typeof a[field] === "number") {
                return (a[field] as number) - (b[field] as number);
            }
            return 0;
        }
    }

    render() {
        const {guild} = this.props;
        const loading = this.loader.isLoading;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 3,
                sorter: ReactRoles.fieldSorter('id'),
                render: (id: string) => {
                    return id === "new" ? "" : id;
                }
            },
            {
                title: 'Role',
                dataIndex: 'roleId',
                key: 'roleId',
                width: 6,
                sorter: ReactRoles.fieldSorter('roleId'),
                render: (roleId: string, record: ReactRole) => {
                    if (record.editing) {
                        return (
                            <Observer>{() =>
                                <RoleSelect disabled={loading} options={guild.roles} value={record.roleId}
                                            onChange={this.onChangeRoleId(record)}/>
                            }</Observer>
                        );
                    }
                    const role = guild.findRoleByID(roleId);
                    return role ? <Role role={role}/> : roleId;
                }
            },
            {
                title: 'Emoji',
                dataIndex: 'emoji',
                key: 'emoji',
                width: 5,
                sorter: ReactRoles.fieldSorter('emoji'),
                render: (emoji: string, record: ReactRole) => {
                    if (record.editing) {
                        return (
                            <Observer>{() =>
                                <EmojiSelect disabled={loading} guild={guild}
                                             value={record.emoji}
                                             onChange={this.onChangeEmoji(record)}/>
                            }</Observer>
                        )
                    }
                    return <Emoji className='react-emoji' guild={guild} emoji={emoji}/>;
                }
            },
            {
                title: 'Channel ID',
                dataIndex: 'channelId',
                key: 'channelId',
                width: 6,
                sorter: ReactRoles.fieldSorter('channelId'),
                render: (channelId: string, record: ReactRole) => {
                    if (record.editing) {
                        return (
                            <Observer>{() =>
                                <ChannelSelect disabled={loading} options={guild.textChannels}
                                               value={record.channelId}
                                               onChange={this.onChangeChannelId(record)}/>
                            }</Observer>
                        )
                    }
                    return guild.findChannelByID(channelId)?.name ?? channelId;
                }
            },
            {
                title: 'Message ID',
                dataIndex: 'messageId',
                key: 'messageId',
                width: 6,
                sorter: ReactRoles.fieldSorter('messageId'),
                render: (messageId: string, record: ReactRole) => {
                    if (record.editing) {
                        return (
                            <Observer>{() =>
                                <Input disabled={loading} placeholder='Message ID'
                                       value={record.messageId}
                                       onChange={this.onChangeMessageId(record)}/>
                            }</Observer>
                        )
                    }

                    return messageId;
                }
            }
        ];

        return (
            <Card title={<Header title='React Roles'
                                 subtitle='React roles give a role to users who react with a specific emoji on a particular message.'/>}
                  style={{textAlign: "left"}}>
                <EditableTable bordered columns={columns}
                               dataSource={guild.reactRoles.toJS().concat([this.reactRoleToAdd])}
                               onAdd={this.submitReactRole}
                               onDelete={this.deleteReactRole}
                               onSave={this.updateReactRole}
                               rowKey='id'
                               pagination={false}/>
            </Card>
        )
    }
}

export default ReactRoles;

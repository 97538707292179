import * as React from "react";
import {Card, message} from "antd";
import Loader from "../../stores/util/Loader";
import {observer} from "mobx-react";
import SocialTracker from "../../stores/models/SocialTracker";
import SocialTrackerTable from "./components/SocialTrackerTable";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import Header from "../../components/Header";

@observer
export class Twitch extends React.Component<GuildComponentProps> {

    loader = new Loader();

    componentDidMount() {
        this.loader.load(this.fetchOptions());
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.loader.load(this.fetchOptions());
        }
    }

    async fetchOptions() {
        if (!this.props.guild.channels || this.props.guild.channels.length === 0) {
            await this.props.guild!.fetchChannels();
        }
        if (!this.props.guild.twitchTrackers || this.props.guild.twitchTrackers.length === 0) {
            await this.props.guild!.fetchTwitchTrackers();
        }
    }

    deleteTracker = async (trackerId: number) => {
        try {
            await this.props.guild.deleteTwitchTracker(trackerId);
            message.success('Deleted Twitch tracker');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    createTracker = async (tracker: SocialTracker) => {
        try {
            await this.loader.load(this.props.guild.createTwitchTracker(tracker));
            message.success('Added Twitch tracker!')
        } catch (e) {
            if (e.response?.status === 400 && e.response.data.message) {
                message.error(e.response.data.message);
            } else {
                message.error('Something went wrong');
            }
        }
    };

    render() {
        return (
            <Card title={<Header title='Twitch Trackers'
                                 subtitle='Add a Twitch streamer by their username and Purity will announce when they go live. Purity will make the announcement in the specified channel.'/>}
                  style={{textAlign: "left"}} loading={this.loader.showLoader}>
                <SocialTrackerTable trackers={this.props.guild.twitchTrackers.toJS()} onAdd={this.createTracker}
                                    onRemove={this.deleteTracker}/>
            </Card>
        )
    }
}

export default Twitch;

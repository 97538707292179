import * as React from "react";
import {StoreContext} from "../stores/Stores";
import {observer} from "mobx-react";

interface GoogleAdProps {
    type?: 'auto' | 'rectangle' | 'vertical' | 'horizontal';
    className?: string;
    [x: string]: any;
}

@observer
export class GoogleAd extends React.Component<GoogleAdProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    componentDidMount(): void {
        if (!this.context.domainStore.hasPremium) {
            ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
        }
    }

    render() {
        if (this.context.domainStore.hasPremium) {
            return <div style={{display: 'none'}}/>
        }

        const {className, type = 'auto', ...props} = this.props;
        return (
            <div className={className + ' google-ad'} {...props}>
                <ins className='adsbygoogle'
                     style={{display: 'block'}}
                     data-adtest="on"
                     data-ad-client='ca-pub-3390506714110523'
                     // data-ad-slot='0'
                     data-ad-format={type}
                     data-full-width-responsive="true"/>
            </div>
        )
    }
}

export default GoogleAd;

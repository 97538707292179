import * as React from "react";
import GuildMember from "../stores/models/GuildMember";
import UserAvatar from "./UserAvatar";

export const MemberItem = ({member}: { member: GuildMember }) => {
    return (
        <span className='member-name'>
            <UserAvatar user={member.user} alt={`Avatar for ${member.displayName}`}/>
            {member.displayName}
        </span>
    )
};

export default MemberItem;

import * as React from "react";
import User from "../stores/models/User";
import {Avatar} from "antd";
import {AvatarProps} from "antd/es/avatar";

interface UserAvatarProps extends AvatarProps {
    user: User;
}

export const UserAvatar = ({user, className,  ...props}: UserAvatarProps) => {
    const src = user?.avatar ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`
        : `https://cdn.discordapp.com/embed/avatars/${parseInt(user.discriminator) % 5}.png`;
    const cls = className ? `avatar ${className}` : 'avatar';

    return (
        <Avatar className={cls} src={src} alt={`Avatar for ${user.username}`} {...props}/>
    )
};

export default UserAvatar;

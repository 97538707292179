import * as React from "react";
import DiscordRole from "../stores/types/DiscordRole";
import Icon from "@ant-design/icons";
import {FaCircle} from "react-icons/all";

export const Role = ({role}: { role: DiscordRole }) => {
    if (role.name === '@everyone') {
        return <span>{role.name}</span>
    }
    const color = '#' + role.color.toString(16);
    return (
        <span className='role' style={{borderColor: color}}>
            <Icon component={FaCircle} style={{color}}/>
            {role.name}
        </span>
    )
};

export default Role;

import * as React from "react";
import Icon from "@ant-design/icons"
import {FaCcAmex, FaCcDinersClub, FaCcDiscover, FaCcJcb, FaCcMastercard, FaCcVisa, FaCreditCard} from "react-icons/all";

interface CardIconProps {
    brand: string;
}

export const CardIcon = ({brand, ...props}: CardIconProps) => {
    let icon = FaCreditCard;
    switch (brand) {
        case 'amex':
            icon = FaCcAmex;
            break;
        case 'diners':
            icon = FaCcDinersClub;
            break;
        case 'discover':
            icon = FaCcDiscover;
            break;
        case 'jcb':
            icon = FaCcJcb;
            break;
        case 'mastercard':
            icon = FaCcMastercard;
            break;
        case 'visa':
            icon = FaCcVisa;
            break;
    }

    return (
        <Icon className='card-icon' component={icon} {...props}/>
    )
};

export default CardIcon;

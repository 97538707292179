import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {auth} from "./Authenticator";
import config from "../app.config";

const domain = (process?.env?.NODE_ENV === 'development') ? config.domain_dev : config.domain_prod;

export class API {
    readonly caller = axios.create({
        baseURL: domain,
        withCredentials: true,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json'
        },
        transformRequest: [(data) => {
            return JSON.stringify(data);
        }],
        transformResponse: [response => {
            if (response && response.data) {
                return response.data;
            }
            return response;
        }]
    });

    applyAuthHeader(config: AxiosRequestConfig = {}) {
        if (auth.token) {
            return Object.assign(config, {
                headers: {
                    'Authorization': `Bearer ${auth.token}`
                }
            });
        } else {
            return config;
        }
    }

    async get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig) {
        return this.caller.get<T, R>(url, this.applyAuthHeader(config))
    }

    async put<T = any, R = AxiosResponse<T>>(url: string, data: any, config?: AxiosRequestConfig) {
        return this.caller.put<T, R>(url, data, this.applyAuthHeader(config))
    }

    async post<T = any, R = AxiosResponse<T>>(url: string, data: any, config?: AxiosRequestConfig) {
        return this.caller.post<T, R>(url, data, this.applyAuthHeader(config))
    }

    async delete(url: string, config?: AxiosRequestConfig) {
        return this.caller.delete(url, this.applyAuthHeader(config))
    }
}

export const api = new API();
export default api;

import * as React from "react";
import Command from "../../../stores/models/Command";
import {observer} from "mobx-react";
import {SwitchChangeEventHandler} from "antd/lib/switch";
import {Switch, Table} from "antd";
import {SwitchProps} from "antd/es/switch";

interface CommandTableProps {
    commands: Command[];
    disabled?: boolean;
    onCommandChange: (command: Command) => void;
    prefix: string;
}

export const CommandTable = ({commands, disabled, onCommandChange, prefix}: CommandTableProps) => {
    const columns = [
        {
            title: 'Status',
            dataIndex: 'aliases',
            key: 'aliases',
            render: (text: any, record: Command, index: number) => {
                return <CommandSwitch record={record} disabled={disabled} onCommandChange={onCommandChange}/>
            }
        },
        {
            title: 'Command',
            dataIndex: 'name',
            key: 'name',
            render: (name: any) => {
                return `${prefix}${name}`
            }
        },
        {
            title: 'Arguments',
            dataIndex: 'format',
            key: 'format',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
        },
    ];

    return (
        <Table bordered columns={columns} dataSource={commands} style={{marginTop: 20}}/>
    )
};

interface CommandSwitchProps extends SwitchProps{
    record: Command;
    onCommandChange: (command: Command) => void;
}

const CommandSwitch = observer(({record, onCommandChange, ...props}: CommandSwitchProps) => {
    const switchChangeHandler: SwitchChangeEventHandler = (checked) => {
        record.setEnabled(checked);
        onCommandChange(record);
    };
    return <Switch checked={record.enabled && !props.disabled} onChange={switchChangeHandler} {...props}/>
});

export default CommandTable;

import * as React from "react";
import {ChangeEvent, ChangeEventHandler} from "react";
import {Button, Card, Form, Input, message} from "antd";
import SettingItem from "../../../components/SettingItem";
import ChannelSelect from "../../../components/ChannelSelect";
import Guild from "../../../stores/models/Guild";
import {observer} from "mobx-react";
import Loader from "../../../stores/util/Loader";
import MessagePreview from "../../../components/MessagePreview";
import {StoreContext} from "../../../stores/Stores";

interface BirthdayConfigProps {
    guild: Guild;
}

@observer
export class BirthdayConfig extends React.Component<BirthdayConfigProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;
    loader = new Loader();

    changeChannel = (channelId: string) => {
        this.props.guild.settings.setBdayChannel(channelId);
    };

    changeMessage: ChangeEventHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const message = event.target.value;
        this.props.guild.settings.setBdayMessage(message);
    };

    submit = async () => {
        try {
            await this.loader.load(this.props.guild.pushBirthdaySettingsToServer());
            message.success('Updated Birthday settings')
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    render() {
        const {guild} = this.props;
        const {bdayChannel, bdayMessage} = guild.settings.birthdaySettings;

        return (
            <Card title='Birthday Message' style={{textAlign: "left"}}>
                <Form labelAlign='left'>
                    <SettingItem label='Channel for Birthday Notifications' wrapperCol={{}}>
                        <ChannelSelect options={guild.textChannels}
                                       value={bdayChannel} onChange={this.changeChannel}/>
                    </SettingItem>

                    <SettingItem label='Birthday Message' help='@user will be replaced with a mention of the user.'
                                 wrapperWidth={24}>
                        <Input.TextArea value={bdayMessage} onChange={this.changeMessage}/>
                        <MessagePreview className={'bday'} message={bdayMessage} user={this.context.userStore.user}
                                        guild={this.props.guild}/>
                    </SettingItem>

                    <Button type='primary' style={{marginTop: 10}} onClick={this.submit} loading={this.loader.isLoading}>Save</Button>
                </Form>
            </Card>
        )
    }
}

export default BirthdayConfig;

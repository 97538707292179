import * as React from "react";
import {ReactNode} from "react";
import {PopoverProps} from "antd/es/popover";
import {Popover} from "antd";
import {QuestionCircleFilled} from "@ant-design/icons";

interface HelpProps extends PopoverProps{
    label?: string | ReactNode;
    help?: string | ReactNode;
}

export const Help = ({label, help, ...props}: HelpProps) => {
    const text = label ?? '';
    return (
        <span>
            {text}<Popover className='help-icon' content={help} {...props}><QuestionCircleFilled/></Popover>
        </span>
    )
};

export default Help;

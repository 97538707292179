import * as React from "react";
import {Card, Descriptions, message, Switch, Tooltip, Typography} from "antd";
import Role from "../../../components/Role";
import {GuildItem} from "../../../stores/models/GuildItem";
import Guild from "../../../stores/models/Guild";
import {CheckOutlined, DeleteOutlined, EditOutlined, TagFilled, TagOutlined} from "@ant-design/icons";
import Emoji from "../../../components/Emoji";
import {observer} from "mobx-react";
import Loader from "../../../stores/util/Loader";
import api from "../../../services/API";
import GuildItemForm from "./GuildItemForm";

interface ShopItemProps {
    item: GuildItem;
    guild: Guild;
}

interface ShopItemState {
    edit: boolean;
}

@observer
export class ShopItem extends React.Component<ShopItemProps, ShopItemState> {
    loader = new Loader();
    state = {
        edit: false
    };

    changeAvailable = (checked: boolean) => {
        this.props.item.setAvailable(checked);
        this.submitChanges();
    };

    toggleEdit = () => {
        this.setState({edit: !this.state.edit});
    };

    deleteGuildItem = async () => {
        if (this.props.item.id) {
            try {
                await this.props.guild.deleteGuildItem(this.props.item.id);
                message.success('Deleted item');
            } catch (e) {
                message.error('Something went wrong');
            }
        }
    };

    submitChanges = async () => {
        if (this.props.item) {
            try {
                await this.loader.load(api.put(`guilds/${this.props.item.guildId}/guild-items/${this.props.item.id}`, this.props.item));
                message.success('Item updated');
            } catch (e) {
                message.error('Something went wrong');
            }
        }
    };

    render() {
        const {item, guild} = this.props;
        const title = <span>{item.id}: {item.emoji &&
        <Emoji guild={guild} emoji={item.emoji}/>} {item.name}</span>;
        const editIcon = this.state.edit ? <CheckOutlined onClick={() => {
                this.submitChanges();
                this.toggleEdit();
            }}/> :
            <EditOutlined onClick={this.toggleEdit}/>;
        const role = item.reqRoleId ? guild.roles.find(r => r.id === item.reqRoleId) : null;

        return (
            <Card className={'guild-item'} title={title} type='inner'
                  extra={
                      <Tooltip title={item.available ? 'Make Unavailable' : 'Make Available'} mouseEnterDelay={1}>
                          <Switch checked={item.available} onChange={this.changeAvailable}
                                  checkedChildren={<TagFilled/>} unCheckedChildren={<TagOutlined/>}/>
                      </Tooltip>
                  }
                  actions={[
                      <Tooltip title={this.state.edit ? 'Save' : 'Edit'} mouseEnterDelay={1}>
                          {editIcon}
                      </Tooltip>,
                      <Tooltip title='Delete' mouseEnterDelay={0.5}>
                          <DeleteOutlined onClick={this.deleteGuildItem}/>
                      </Tooltip>
                  ]}>
                {this.state.edit ?
                    <GuildItemForm item={item} guild={guild}/>
                    :
                    <div>
                        <Typography.Paragraph>{item.description}</Typography.Paragraph>
                        <Descriptions size='small' column={3} layout='vertical' bordered>
                            <Descriptions.Item
                                label='Price'>{item.reqCurrency} {guild.settings.currencyName}</Descriptions.Item>
                            <Descriptions.Item label='User Limit'>{item.limitForUser ?? 'No Limit'}</Descriptions.Item>
                            <Descriptions.Item label='Quantity'>{item.qty ?? 'No Limit'}</Descriptions.Item>
                            <Descriptions.Item label='Required Level'>{item.reqLevel ?? 'None'}</Descriptions.Item>
                            <Descriptions.Item label='Required Role'>{role ?
                                <Role role={role}/> : "None"}</Descriptions.Item>
                        </Descriptions>
                    </div>
                }
            </Card>
        )
    }
}

export default ShopItem;

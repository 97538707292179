import * as React from "react";
import WelcomeMessage from "./scenes/WelcomeMessage";
import BasicSettings from "./scenes/BasicSettings";
import {observer} from "mobx-react";
import {GuildComponentProps} from "../../types/GuildComponentProps";

@observer
export class GeneralConfig extends React.Component<GuildComponentProps> {

    render() {
        const guild = this.props.guild;
        if (!guild || !guild.settings) {
            return <div>loading</div>
        }

        return (
            <div>
                <BasicSettings guild={guild}/>
                <WelcomeMessage guild={guild}/>
            </div>
        )
    }
}

export default GeneralConfig;

import * as React from "react";
import {Select} from "antd";
import {SelectProps} from "antd/es/select";
import GuildMember from "../stores/models/GuildMember";
import MemberItem from "./MemberItem";

interface UserSelectProps extends Omit<SelectProps<string>, 'options'> {
    options: GuildMember[];
}

interface UserSelectState {
    results: GuildMember[];
}

export class UserSelect extends React.Component<UserSelectProps> {
    state: UserSelectState = {
        results: []
    };

    onSearch = (value: string) => {
        console.debug('search', value);
        if (value.length === 0) {
            this.setState({results: this.props.options});
        } else {
            const results = this.props.options.filter(member => (member &&
                (member.nick?.toLowerCase().indexOf(value.toLowerCase()) >= 0
                    || member.user?.username.toLowerCase().indexOf(value.toLowerCase()) >= 0
                    || member.user?.id.indexOf(value.toLowerCase()) >= 0)));
            this.setState({results});
        }
    };

    onChange = (value: any, option: any) => {
        if (!value) {
            this.setState({results: this.props.options});
        }
        if (this.props.onChange) {
            this.props.onChange(value, option);
        }
    };

    componentDidMount() {
        this.setState({results: this.props.options});
    }

    render() {
        let {options, onChange, ...props} = this.props;

        return (
            <Select showSearch allowClear placeholder='User' filterOption={false}
                    onSearch={this.onSearch} onChange={this.onChange}
                    {...props}>
                {this.state.results.slice(0, 50).map((member: GuildMember) => (
                    <Select.Option key={member.user.id} value={member.user.id}>
                        <MemberItem member={member}/>
                    </Select.Option>
                ))}

                {this.state.results.length > 50 &&
                <Select.Option key='moreOptions' disabled value='more'>Start typing for more options...</Select.Option>
                }
            </Select>
        )
    }
}

export default UserSelect;

import {action, observable} from "mobx";
import Command from "./Command";

interface CommandMap {
    [name: string]: Command
}

export class CommandGroup {
    name!: string;
    @observable commands: CommandMap = {};
    @observable enabled: boolean = true;

    constructor(json: any) {
        this.name = json.name;
        json.commands.map((cmd: any) => new Command(cmd)).forEach((command: Command) => {
            if (!command.ownerOnly && command.group.toLowerCase() !== 'utility' && command.group.toLowerCase() !== 'commands') {
                this.commands[command.name] = command;
            }
        });

        this.enabled = json.enabled != null ? json.enabled : this.enabled;
    }

    @action setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    get key() {
        return this.name;
    }
}

export default CommandGroup;

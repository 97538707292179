import * as React from "react";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import {Button, Card, Empty, message, Modal, Skeleton, Space} from "antd";
import Header from "../../components/Header";
import Loader from "../../stores/util/Loader";
import TicketTypeItem from "./scenes/TicketTypeItem";
import {observer} from "mobx-react";
import {TicketType} from "../../stores/models/TicketType";
import TicketTypeForm from "./scenes/TicketTypeForm";

interface TicketsState {
    modalOpen: boolean;
}

@observer
export class Tickets extends React.Component<GuildComponentProps, TicketsState> {
    loader = new Loader();
    ticketTypeToAdd = new TicketType();
    state = {
        modalOpen: false
    };

    componentDidMount() {
        this.loader.load(this.fetchOptions());
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.loader.load(this.fetchOptions());
        }
    }

    async fetchOptions() {
        return this.loader.load(this.props.guild.fetchTicketTypes());
    }

    openModal = () => this.setState({modalOpen: true});

    closeModal = () => this.setState({modalOpen: false});

    submitTicketType = async () => {
        try {
            await this.loader.load(this.props.guild.createTicketType(this.ticketTypeToAdd));
            this.ticketTypeToAdd.update({}); // clear the item to add
            message.success('Added ticket type!');
            this.setState({modalOpen: false});
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    render() {
        const {guild} = this.props;
        return (
            <Card title={<Header title='Tickets'
                                 subtitle='Allow users to submit support tickets for your team to track and address.'/>}
                  style={{textAlign: "left"}}
                  extra={[<Button type='primary' onClick={this.openModal}>Add Ticket Type</Button>]}>
                {this.loader.showLoader &&
                <Skeleton/>
                }

                {!this.loader.isLoading && guild.ticketTypes.length === 0 &&
                <Empty description="You haven't added any ticket types yet."/>
                }

                <Space direction='vertical' style={{width: '100%'}}>
                    {guild.ticketTypes.map(tt => <TicketTypeItem ticketType={tt} guild={guild}/>)}
                </Space>

                <Modal className='ticket-type-modal' title='Create a Ticket Type' destroyOnClose
                       visible={this.state.modalOpen} onCancel={this.closeModal}
                       okText='Submit' onOk={this.submitTicketType}
                       okButtonProps={{disabled: !this.ticketTypeToAdd.isValid}}>
                    <TicketTypeForm ticketType={this.ticketTypeToAdd} guild={guild}/>
                </Modal>
            </Card>
        )
    }
}

export default Tickets;

import * as React from "react";
import {ChangeEventHandler} from "react";
import {Button, Form, Input, Space, Tooltip} from "antd";
import Guild from "../../../stores/models/Guild";
import {observer} from "mobx-react";
import {TicketType} from "../../../stores/models/TicketType";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import Help from "../../../components/Help";
import ChannelSelect from "../../../components/ChannelSelect";

interface TicketTypeFormProps {
    ticketType: TicketType;
    guild: Guild;
}

interface TicketTypeFormState {
    questionToAdd: string;
}

@observer
export class TicketTypeForm extends React.Component<TicketTypeFormProps, TicketTypeFormState> {

    state = {
        questionToAdd: ''
    };

    changeTopic: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        this.props.ticketType.setTopic(target.value);
    };

    changeChannel = (channelId: string) => {
        this.props.ticketType.setChannelId(channelId);
    };

    changeQuestionToAdd: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        this.setState({questionToAdd: target.value});
    };

    addQuestion = () => {
        this.props.ticketType.addQuestion(this.state.questionToAdd);
        this.setState({questionToAdd: ''})
    };

    removeQuestion = (index: number) => {
        this.props.ticketType.removeQuestion(index);
    };

    render() {
        const {ticketType, guild} = this.props;
        return (
            <Form layout='vertical'>
                <Form.Item label="Name" required>
                    <Input value={ticketType.topic} onChange={this.changeTopic}/>
                </Form.Item>
                <Form.Item label={<Help label='Channel'
                                        help='The text channel that new tickets of this type will be posted to.'/>}
                           required>
                    <ChannelSelect options={guild.textChannels} value={ticketType.channelId}
                                   onChange={this.changeChannel}/>
                </Form.Item>
                <Form.Item label="Questions">
                    <ol>
                        <Space direction='vertical' style={{width: '100%'}}>
                            {ticketType.questions.map((question, i) => {
                                return (
                                    <li key={i}>
                                        <Input value={question} addonAfter={
                                            <Tooltip title='Delete' mouseEnterDelay={0.5}>
                                                <Button type='primary' danger shape='circle' size='small'
                                                        icon={<MinusOutlined/>}
                                                        onClick={() => this.removeQuestion(i)}/>
                                            </Tooltip>
                                        }/>
                                    </li>
                                );
                            })}
                            <li key={'new'} style={{listStyleType: 'none'}}>
                                <Input placeholder='New Question' value={this.state.questionToAdd} onChange={this.changeQuestionToAdd}
                                       onPressEnter={this.addQuestion}
                                       addonAfter={
                                           <Tooltip title='Add' mouseEnterDelay={0.5}>
                                               <Button type='primary' shape='circle' size='small' icon={<PlusOutlined/>}
                                                       disabled={this.state.questionToAdd.length === 0}
                                                       onClick={this.addQuestion}/>
                                           </Tooltip>
                                       }/>
                            </li>
                        </Space>
                    </ol>
                </Form.Item>
            </Form>
        )
    }
}

export default TicketTypeForm;

import {action, computed, observable} from 'mobx';
import {auth} from "../../services/Authenticator";

export enum STATES {
    INITIAL,
    LOADING,
    ERROR,
    CLIENT_ERROR,
    UNAUTHENTICATED,
    LOADED,
    FAILED
}

// loading object
export class Loader {
    @observable state = STATES.INITIAL;
    timeout = 0;
    timer?: NodeJS.Timeout;
    @observable timeoutDone = false;
    @observable status = 0;

    constructor(timeout = 0) {
        this.timeout = timeout;
    }

    @action
    completeTimeout() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timeoutDone = false;
        if (this.timeout > 0) {
            this.timer = setTimeout(action(() => this.timeoutDone = true), this.timeout);
        } else {
            this.timeoutDone = true;
        }
    }

    @computed
    get showLoader() {
        return this.timeoutDone && this.isLoading;
    }

    @computed
    get isLoading() {
        return this.state === STATES.LOADING;
    }

    @computed
    get isLoaded() {
        return this.state === STATES.LOADED;
    }

    @computed
    get hasError() {
        return this.state === STATES.ERROR;
    }

    @computed
    get clientError() {
        return this.state === STATES.CLIENT_ERROR;
    }

    @computed
    get unauthenticated() {
        return this.state === STATES.UNAUTHENTICATED;
    }

    @computed
    get failed() {
        return this.state === STATES.FAILED;
    }

    @computed
    get complete() {
        return this.state === STATES.LOADED;
    }

    @action
    updateState(state: STATES) {
        this.state = state;
    }

    @action
    updateStatus(status: number) {
        this.status = status;
    }

    async load(promise: Promise<any>) {
        let {LOADING, ERROR, CLIENT_ERROR, UNAUTHENTICATED, LOADED, FAILED} = STATES;
        try {
            this.updateState(LOADING);
            this.completeTimeout();
            let data = await promise;
            this.updateState(LOADED);
            return data;
        } catch (e) {
            if (e.response) {
                console.debug('RESPONSE', e.response);
                if (e.response.status) {
                    this.updateStatus(e.response.status);
                }
                if (e.response.status && e.response.status === 401) {
                    console.debug('LOGOUT');
                    this.updateState(UNAUTHENTICATED);
                    auth.logout();
                } else if (e.response.status && e.response.status < 500) {
                    this.updateState(CLIENT_ERROR);
                } else {
                    this.updateState(ERROR);
                }
            } else {
                this.updateState(FAILED);
            }
            throw e;
        }
    }
}

export let createLoader = action((timeout: number = 500) => {
    return new Loader(timeout)
});

export default Loader

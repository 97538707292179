import Pin from "./Pin";
import DiscordUser from "../types/DiscordUser";
import {computed} from "mobx";

export class User implements DiscordUser{
    id!: string;
    rank!: string;
    votes!: number;
    vPoints!: number;
    gems!: number;
    cmdCount!: number;
    joinDate!: Date;
    pins: Pin[] = [];
    // Disocrd User
    accessToken: string;
    avatar: string;
    bot: boolean;
    discriminator: string;
    email: string;
    flags: number;
    locale: string;
    mfa_enabled: boolean;
    premium_type: number;
    refreshToken: string;
    username: string;
    verified: boolean;

    constructor(json: any) {
        this.id = json.id;
        this.rank = json.rank;
        this.votes = json.votes;
        this.vPoints = json.vPoints;
        this.gems = json.gems;
        this.cmdCount = json.cmdCount;
        this.joinDate = json.joinDate;
        // Disocrd User
        this.accessToken = json.accessToken;
        this.avatar = json.avatar;
        this.bot = json.bot;
        this.discriminator = json.discriminator;
        this.email = json.email;
        this.flags = json.flags;
        this.locale = json.locale;
        this.mfa_enabled = json.mfa_enabled;
        this.premium_type = json.premium_type;
        this.refreshToken = json.refreshToken;
        this.username = json.username;
        this.verified = json.verified;

        if (json.pins) {
            this.pins = json.pins.map((pin: any) => new Pin(pin));
        }
    }

    @computed
    get displayName() {
        return `${this.username}#${this.discriminator}`;
    }
}

export default User;

import User from "./models/User";
import {action, observable} from "mobx";
import api from "../services/API";

export class UserStore {
    @observable user?: User;

    @action
    setUser(user: any) {
        this.user = new User(user);
    }

    async fetchUser() {
        const {data} = await api.get<any>('users/me');
        this.setUser(data);
    }
}

export default UserStore;

import * as React from "react";
import {Table} from "antd";
import PaymentMethod from "./PaymentMethod";

interface PaymentMethodListProps {
    options: stripe.paymentMethod.PaymentMethod[];
    value?: string;
    onChange: (id: string) => void;
}

export class PaymentMethodList extends React.Component<PaymentMethodListProps> {

    static columns = [
        {
            title: 'Card',
            dataIndex: 'card',
            key: 'card',
            width: 10,
        },
        {
            title: 'Name on Card',
            dataIndex: 'name',
            key: 'name',
            width: 8,
        },
        {
            title: 'Expires',
            dataIndex: 'expires',
            key: 'expires',
            width: 4,
        }
    ];

    onSelectionChange = ([id]: (string | number)[]) => {
        this.props.onChange(id as string);
    };

    render() {
        const data = this.props.options.map(method => {
            const {exp_month, exp_year} = method.card!;
            return {
                id: method.id,
                card: (<PaymentMethod method={method}/>),
                name: method.billing_details.name,
                expires: `${exp_month}/${exp_year}`
            }
        });

        const values = this.props.value ? [this.props.value] : [];

        return (
            <Table bordered columns={PaymentMethodList.columns} dataSource={data} rowKey='id'
                   pagination={false}
                   rowSelection={{
                       type: 'radio',
                       columnWidth: 2,
                       onChange: this.onSelectionChange,
                       selectedRowKeys: values
                   }}/>
        )
    }
}

export default PaymentMethodList;

import {action, observable} from "mobx";
import moment, {Moment} from "moment";
import api from "../../services/API";

export class Giveaway {
    id!: number;
    guildId!: string;
    messageId!: string;
    @observable channelId!: string;
    @observable sponsorId!: string;
    @observable prize!: string;
    @observable levelRequirement?: number;
    @observable roleId?: string;
    @observable numWinners!: number;
    @observable giveawayTime!: Moment;
    reminderTime?: Moment;
    wasRun!: boolean;

    constructor(json?: any) {
        if (json) {
            this.update(json);
        }
    }

    @action
    update(json: any) {
        this.id = json.id;
        this.guildId = json.guildId;
        this.messageId = json.messageId;
        this.channelId = json.channelId;
        this.sponsorId = json.sponsorId;
        this.prize = json.prize;
        this.levelRequirement = json.levelRequirement;
        this.roleId = json.roleId;
        this.numWinners = json.numWinners ?? 1;
        this.wasRun = json.wasRun;
        this.giveawayTime = json.giveawayTime ? moment(json.giveawayTime) : moment().add(1, 'hour');
        this.reminderTime = json.reminderTime ? moment(json.reminderTime) : undefined;
    }

    @action setGuildId(guildId: string) {
        this.guildId = guildId;
    }

    @action setChannelId(channelId: string) {
        this.channelId = channelId;
    }

    @action setSponsorId(sponsorId: string) {
        this.sponsorId = sponsorId;
    }

    @action setPrize(prize: string) {
        this.prize = prize;
    }

    @action setLevelRequirement(levelRequirement?: number) {
        this.levelRequirement = levelRequirement ?? 0;
    }

    @action setRoleId(roleId: string) {
        this.roleId = roleId;
    }

    @action setNumWinners(numWinners?: number) {
        this.numWinners = numWinners ?? 1;
    }

    @action setGiveawayTime(giveawayTime: Moment) {
        this.giveawayTime = giveawayTime;
    }

    async create() {
        const {data} = await api.post(`guilds/${this.guildId}/giveaways`, this);
        this.update(data);
    }

    async delete() {
        await api.delete(`guilds/${this.guildId}/giveaways/${this.id}`);
    }
}

export default Giveaway;

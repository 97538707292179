export const Routes = {
  home: '/',
  login: '/login',
  profile: '/profile',
  premium: '/premium',
  guild: '/guilds/:guildId',
  dashboard: '/guilds/:guildId/dashboard',
  general: '/guilds/:guildId/general',
  commands: '/guilds/:guildId/commands',
  birthdays: '/guilds/:guildId/birthdays',
  economy: '/guilds/:guildId/economy',
  giveaways: '/guilds/:guildId/giveaways',
  leveling: '/guilds/:guildId/leveling',
  ranks: '/guilds/:guildId/ranks',
  reactRoles: '/guilds/:guildId/react-roles',
  moderation: '/guilds/:guildId/moderation',
  twitch: '/guilds/:guildId/twitch',
  serverShop: '/guilds/:guildId/server-shop',
  tickets: '/guilds/:guildId/tickets',
};

export const guildRoute = (route: string, guildId: string | undefined) => {
  if (!guildId) {
    return '';
  }
  return route.replace(':guildId', guildId);
};

export const PageTitles = {
  [Routes.home]: 'Dashboard',
  [Routes.dashboard]: 'Dashboard',
  [Routes.general]: 'General Configuration',
  [Routes.commands]: 'Command Toggle',
  [Routes.birthdays]: 'Birthdays',
  [Routes.economy]: 'Economy',
  [Routes.giveaways]: 'Giveaways',
  [Routes.leveling]: 'Leveling System',
  [Routes.moderation]: 'Moderation',
  [Routes.ranks]: 'Ranks',
  [Routes.twitch]: 'Twitch',
};

export default Routes;

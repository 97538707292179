import SettingItem from "../../components/SettingItem";
import {Button, Card, message, Select, Switch} from "antd";
import * as React from "react";
import {observer} from "mobx-react";
import Loader from "../../stores/util/Loader";
import RoleSelect from "../../components/RoleSelect";
import ChannelSelect from "../../components/ChannelSelect";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import Help from "../../components/Help";

@observer
export class Moderation extends React.Component<GuildComponentProps> {

    loader = new Loader();

    componentDidMount(): void {
        this.loader.load(this.fetchOptions());
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.loader.load(this.fetchOptions());
        }
    }

    async fetchOptions() {
        const {guild} = this.props;
        if (guild.channels.length <= 0) {
            await guild.fetchChannels();
        }

        if (guild.roles.length <= 0) {
            await guild.fetchRoles();
        }
    }

    changeEmpowerAdmins = (checked: boolean) => {
        this.props.guild.settings.setEmpowerAdmins(checked);
    };

    changeAdminOnly = (checked: boolean) => {
        this.props.guild.settings.setAdminOnly(checked);
    };

    changeAdminRole = (adminRole: string) => {
        this.props.guild.settings.setAdminRole(adminRole);
    };

    changeModRole = (modRole: string) => {
        this.props.guild.settings.setModRole(modRole);
    };

    changeModLogs = (modLogs?: string) => {
        this.props.guild.settings.setModLogs(modLogs);
    };

    changeDiscordInviteLinks = (modLogs: boolean) => {
        this.props.guild.settings.setDiscordInviteLinks(modLogs);
    };

    changeLogImageChannel = (modLogs?: string) => {
        this.props.guild.settings.setLogImageChannel(modLogs);
    };

    changeWordBlacklist = (wordBlacklist?: string[]) => {
        let words: string[] = [];
        wordBlacklist?.forEach(w => {
            words = words.concat(w.split(','))
        })
        words.forEach((w, i) => words[i] = w.trim());

        this.props.guild.settings.setWordBlacklist(words);
    };

    submit = async () => {
        try {
            await this.loader.load(this.props.guild.pushModerationSettingsToServer());
            message.success('Updated Moderation settings!')
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    render() {
        const {guild} = this.props;
        const {empowerAdmins, adminOnly, adminRole, modRole, modLogs, discordInviteLinks, logImageChannel, wordBlacklist} = guild.settings.moderationSettings!;

        return (
            <Card title='Moderation' style={{textAlign: "left"}}>
                <SettingItem label={<Help label='Empower Admins'
                                          help='Allows users with Discord Administrator permission to configure Purity.'/>}
                             inline>
                    <Switch checked={empowerAdmins} onChange={this.changeEmpowerAdmins}/>
                </SettingItem>

                <SettingItem label={<Help label='Admins Only' help='If turned on, only admins can use bot commands.'/>}
                             inline>
                    <Switch checked={adminOnly} onChange={this.changeAdminOnly}/>
                </SettingItem>

                <SettingItem label={<Help label='Admin Role'
                                          help='Users with this role will be able to configure purity. Empower Admins does not need to be on for users with the Admin Role.'/>}>
                    <RoleSelect disabled={this.loader.isLoading} options={guild.roles}
                                value={adminRole} onChange={this.changeAdminRole}/>
                </SettingItem>

                <SettingItem label={<Help label='Mod Role'
                                          help='Users with this role will be able to use moderator commands such as kick, ban, purge, etc.'/>}>
                    <RoleSelect disabled={this.loader.isLoading} options={guild.roles}
                                value={modRole} onChange={this.changeModRole}/>
                </SettingItem>

                <SettingItem label={<Help label='Mod Logs Channel'
                                          help='Purity will send a message to this channel for each change made to the server.'/>}>
                    <ChannelSelect options={guild.textChannels} value={modLogs} onChange={this.changeModLogs}/>
                </SettingItem>

                <SettingItem label={<Help label='Block Discord Invite Links'
                                          help='If someone attempts to send a message containing a Discord invite link, the message will immediately be deleted by Purity.'/>}>
                    <Switch checked={discordInviteLinks} onChange={this.changeDiscordInviteLinks}/>
                </SettingItem>

                <SettingItem label={<Help label='Image Logs Channel'
                                          help='Purity will send a message to this channel for each image or attachment posted to the server.'/>}>
                    <ChannelSelect options={guild.textChannels} value={logImageChannel}
                                   onChange={this.changeLogImageChannel}/>
                </SettingItem>

                <SettingItem label={<Help label='Word Blacklist'
                                          help='If someone attempts to send a message containing a word in this list, the message will immediately be deleted by Purity.'/>}>
                    <Select mode="tags" allowClear placeholder="Blacklist" value={wordBlacklist ?? []}
                            onChange={this.changeWordBlacklist}/>
                </SettingItem>

                <Button type='primary' onClick={this.submit} loading={this.loader.isLoading}>Save</Button>
            </Card>
        )
    }
}

export default Moderation;

export class Pin {
    id!: number;
    userId!: string;
    pinId!: string;
    dateObtained!: Date;

    constructor(json: Pin) {
        this.id = json.id;
        this.userId = json.userId;
        this.pinId = json.pinId;
        this.dateObtained = json.dateObtained;
    }

}

export default Pin;

import React from 'react';
import {Button, Dropdown, Form, Menu, Table, Tooltip} from 'antd';
import {MoreOutlined, PlusOutlined} from "@ant-design/icons";
import {TableProps} from "antd/es/table";
import Editable from "../stores/models/Editable";
import Model from "../stores/types/Model";
import {Observer, observer} from 'mobx-react';

type EditableModel = Editable & Model;

interface EditableTableProps extends TableProps<any> {
    onAdd: () => void;
    onSave: (item: any) => void;
    onDelete?: (item: any) => any;
}

@observer
export class EditableTable<Item extends EditableModel> extends React.Component<EditableTableProps> {

    edit = (record: Item) => {
        record.setEditing(true);
        console.debug(record);
    };

    save = async (record: Item) => {
        try {
            await this.props.onSave(record);
            record.setEditing(false);
        } catch (e) {

        }
    };

    cancel = async (record: Item) => {
        record.setEditing(false);
    };

    render() {

        const {columns, dataSource, ...props} = this.props;

        // Kinda hacky way to get it to observer a change
        dataSource?.some(r => r.editing);

        const columnsWithAction = columns!.concat([
            {
                dataIndex: 'action',
                key: 'action',
                width: 2,
                render: (_: any, record: Item) => {
                    if (record.isNew) {
                        return (
                            <Observer>{() =>
                                <Tooltip title='Add' mouseEnterDelay={1}>
                                    <Button type='primary' shape='circle' icon={<PlusOutlined/>}
                                            disabled={!record.isComplete}
                                            onClick={this.props.onAdd}/>
                                </Tooltip>
                            }</Observer>
                        )
                    }
                    return (
                        record.editing ?
                            <div>
                                <Button type='link' onClick={() => this.save(record)}>Save</Button>
                            </div>
                            :
                            <Dropdown trigger={['click']}
                                      overlay={
                                          <Menu>
                                              <Menu.Item key='edit' onClick={() => this.edit(record)}>Edit</Menu.Item>
                                              {this.props.onDelete &&
                                              <Menu.Item key='delete'
                                                         onClick={() => this.props.onDelete!(record)}>Delete</Menu.Item>
                                              }
                                          </Menu>
                                      }>
                                <Button type="text">
                                    <MoreOutlined/>
                                </Button>
                            </Dropdown>
                    )
                }
            }]);


        return (
            <Form component={false}>
                <Table
                    columns={columnsWithAction}
                    dataSource={dataSource}
                    rowClassName="editable-row"
                    {...props}
                />
            </Form>
        );
    }
}

export default EditableTable;

import {action, computed, observable} from "mobx";

export class GuildItem {
    id?: number;
    guildId?: string;
    @observable name?: string;
    @observable emoji?: string | null;
    @observable description?: string | null;
    @observable reqCurrency?: number | null;
    @observable reqLevel?: number | null;
    @observable reqRoleId?: string | null;
    @observable available!: boolean;
    @observable limitForUser?: number | null;
    @observable qty?: number | null;

    constructor(json?: any) {
        if (json) {
            this.update(json);
        }
    }

    @action
    update(json: any) {
        this.id = json.id;
        this.guildId = json.guildId;
        this.name = json.name;
        this.emoji = json.emoji;
        this.description = json.description;
        this.reqCurrency = json.reqCurrency;
        this.reqLevel = json.reqLevel;
        this.reqRoleId = json.reqRoleId;
        this.available = json.available;
        this.limitForUser = json.limitForUser;
        this.qty = json.qty;
    }

    @action
    setName(name: string) {
        this.name = name;
    }

    @action
    setEmoji(emoji: string | null) {
        this.emoji = emoji;
    }

    @action
    setDescription(description: string | null) {
        this.description = description;
    }

    @action
    setReqCurrency(reqCurrency?: number | null) {
        this.reqCurrency = reqCurrency;
    }

    @action
    setReqLevel(reqLevel?: number | null) {
        this.reqLevel = reqLevel;
    }

    @action
    setReqRoleId(reqRoleId?: string | null) {
        this.reqRoleId = reqRoleId;
    }

    @action
    setAvailable(available: boolean) {
        this.available = available;
    }

    @action
    setLimitForUser(limitForUser?: number | null) {
        this.limitForUser = limitForUser;
    }

    @action
    setQty(qty?: number | null) {
        this.qty = qty;
    }

    @computed
    get isValid() {
        return this.name && this.name.length > 0
        && !(this.reqCurrency && this.reqCurrency < 0)
        && !(this.reqLevel && this.reqLevel < -1)
        && !(this.limitForUser && this.limitForUser < -1)
        && !(this.qty && this.qty < -1);
    }

}

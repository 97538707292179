
export function displayPlan(plan: any) {
    const interval = plan?.interval;
    const amount = plan?.amount ? (plan?.amount / 100).toFixed(2) : undefined;
    const currency = plan?.currency?.toUpperCase();
    const symbol = currency === 'USD' ? '$' : '';
    return `${symbol}${amount} ${currency} per ${interval}`;
}

export default displayPlan;

import {action, observable} from "mobx";

export class Command {
    name!: string;
    aliases!: string[];
    description!: string;
    details!: string;
    examples!: string;
    format!: string;
    group!: string;
    nsfw!: boolean;
    ownerOnly!: boolean;
    usage!: string;

    @observable enabled: boolean = true;

    constructor(json: any) {
        this.name = json.name;
        this.aliases = json.aliases;
        this.description = json.description;
        this.details = json.details;
        this.examples = json.examples;
        this.format = json.format;
        this.group = json.group;
        this.nsfw = json.nsfw;
        this.ownerOnly = json.ownerOnly;
        this.usage = json.usage;
        this.enabled = json.enabled != null ? json.enabled : this.enabled;
    }

    @action setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    get key() {
        return this.name;
    }
}

export default Command;

import Guild from "./models/Guild";
import api from "../services/API";
import {action, computed, observable, runInAction} from "mobx";
import {ERRORS, ServerError} from "../types/ServerError";

export class DomainStore {
    guilds = observable<Guild>([]);
    @observable guild?: Guild;
    @observable error?: ServerError;

    @action
    async fetchGuilds() {
        try {
            const {data} = await api.get<any[]>('guilds');
            runInAction(() => this.guilds.replace(data.map(guild => new Guild(guild))));
            if (data.length === 0) {
                this.setError(ERRORS.NO_SERVERS)
            }
        } catch (e) {
            if (!e.response) {
                this.setError(ERRORS.SERVER_DOWN);
            }
            throw e;
        }
    }

    @action
    setGuild(guild: Guild) {
        this.guild = guild;
    }

    @action
    setError(error?: ServerError) {
        this.error = error;
    }

    setGuildById(id: string) {
        const guild = this.findGuildById(id);
        if (guild) {
            this.setGuild(guild);
        }
    }

    async fetchGuild(id: string) {
        try {
            const {data} = await api.get(`guilds/${id}`);
            const guild = this.findGuildById(id);
            if (guild) {
                guild.update(data);
                this.setGuild(guild);
            } else {
                this.setGuild(new Guild(data));
            }
            await guild?.fetchChannels();
            await guild?.fetchEmojis();
            await guild?.fetchRoles();

            this.setError(undefined);
        } catch (e) {
            if (e.response?.status === 403) {
                this.setError(ERRORS.MISSING_PERMISSONS);
            } else if (e.response?.status === 404) {
                this.setError(ERRORS.GUILD_NOT_FOUND);
            } else {
                this.setError(ERRORS.INTERNAL_SERVER_ERROR);
            }
        }
    }

    findGuildById(id: string) {
        return this.guilds.find(guild => guild.id === id);
    }

    @computed
    get hasPremium() {
        return !!this.guilds.find(g => g.premium && g.premium > 0);
    }
}

export default DomainStore;

import * as React from "react";
import {abbreviate} from "../util/abbreviate";
import {AvatarProps} from "antd/es/avatar";
import {Avatar} from "antd";
import DiscordGuild from "../stores/types/DiscordGuild";

interface GuildAvatarProps extends AvatarProps {
    guild: DiscordGuild;
}

export const GuildAvatar = ({guild, ...props}: GuildAvatarProps) => {
    const src = guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png` : undefined;
    const abbreviation = !src ? abbreviate(guild.name) : undefined;

    return (
        <Avatar src={src} alt={`Avatar for ${guild?.name}`} {...props}>
            {abbreviation}
        </Avatar>
    )
};

export default GuildAvatar;

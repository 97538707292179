import * as React from "react";
import Guild from "../stores/models/Guild";
import Twemoji from "./Twemoji";

interface EmojiProps {
    guild?: Guild;
    emoji: string;
    [x: string]: any;
}

export const Emoji = ({guild, emoji, ...props}: EmojiProps) => {
    let name = emoji;

    const guildEmoji = guild?.emojis.find(e => e.id === emoji);
    if (!guildEmoji) {
        return <Twemoji emoji={emoji} {...props}/>;
    }

    name = `${guildEmoji.name}`;
    const src = `https://cdn.discordapp.com/emojis/${guildEmoji.id}`;

    return (
        <span {...props}>
            <img className='emoji' src={src} alt={name}/>
        </span>
    )
};

export default Emoji;

import * as React from "react";
import {ChangeEventHandler} from "react";
import {Button, Card, Col, Form, Input, message, Row, Switch} from "antd";
import SettingItem from "../../../components/SettingItem";
import Guild from "../../../stores/models/Guild";
import Loader from "../../../stores/util/Loader";
import {observer} from "mobx-react";
import ChannelSelect from "../../../components/ChannelSelect";
import Header from "../../../components/Header";
import MessagePreview from "../../../components/MessagePreview";
import {StoreContext} from "../../../stores/Stores";

interface WelcomeMessageProps {
    guild: Guild
}

@observer
export class WelcomeMessage extends React.Component<WelcomeMessageProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    channelLoader = new Loader();

    onSubmit = async () => {
        try {
            await this.loader.load(this.props.guild.pushWelcomeSettingsToServer());
            message.success('Welcome settings updated');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    changeWelcomeStatus = (checked: boolean) => {
        this.props.guild.settings.setWelcomeStatus(checked);
    };

    changeWelcomeChannel = (value: string) => {
        this.props.guild.settings.setWelcomeChannel(value);
    };

    changeWelcomeMessage: ChangeEventHandler<HTMLTextAreaElement> = ({target: {value}}) => {
        this.props.guild.settings.setWelcome(value);
    };

    componentDidMount(): void {
        this.loader.load(this.fetchOptions());
    }

    async fetchOptions() {
        if (this.props.guild.channels.length <= 0) {
           await this.channelLoader.load(this.props.guild.fetchChannels());
        }

        if (this.props.guild.roles.length <= 0) {
            await this.props.guild.fetchRoles();
        }
    }

    render() {
        const {textChannels} = this.props.guild;
        const {welcomeStatus, welcomeChannel, welcome} = this.props.guild.settings.welcomeSettings;

        return (
            <Card title={<Header title='Welcome Message'
                                 subtitle='The welcome message is sent whenever a user joins your server.'/>}
                  style={{textAlign: "left", marginTop: 20}}>
                <Form labelAlign='left'>
                    <SettingItem label='Send Welcome Message' inline>
                        <Switch checked={welcomeStatus} onChange={this.changeWelcomeStatus}/>
                    </SettingItem>

                    <SettingItem label={'Channel for Welcome Messages'}>
                        <ChannelSelect loading={this.channelLoader.isLoading} options={textChannels}
                                       value={welcomeChannel} onChange={this.changeWelcomeChannel}/>
                    </SettingItem>

                    <SettingItem label='Welcome Message' help='@user will mention the user joining your server. @guild will be replaced with the name of your server.' wrapperWidth={24}>
                        <Row>
                            <Col xs={24} sm={24} md={10}>
                                <Input.TextArea value={welcome} onChange={this.changeWelcomeMessage}/>
                            </Col>
                            <Col xs={24} sm={24} md={{span: 13, offset: 1}}>
                                <MessagePreview className={'welcome'} message={welcome} user={this.context.userStore.user}
                                                guild={this.props.guild}/>
                            </Col>
                        </Row>
                    </SettingItem>
                </Form>
                <Button type='primary' onClick={this.onSubmit}>Save</Button>
            </Card>
        )
    }
};

export default WelcomeMessage;

import * as React from "react";
import twemoji from 'twemoji';

interface TwemojiProps {
    emoji: string;
    [x: string]: any;
}

export const Twemoji = ({emoji, ...props}: TwemojiProps) => {
    let html = '';
    try {
        html = twemoji.parse(emoji)
    } catch (e) {
        return <span {...props}>{emoji}</span>
    }

    return (
        <span {...props}
              dangerouslySetInnerHTML={{
                  __html: html
              }}
        />
    )
};

export default Twemoji;

import * as React from "react";
import {Select} from "antd";
import {SelectProps} from "antd/es/select";
import DiscordGuild from "../stores/types/DiscordGuild";
import GuildName from "./GuildName";

interface GuildSelectProps extends Omit<SelectProps<string>, 'value'|'options'> {
    options: DiscordGuild[];
    value?: string | null;
}

export const GuildSelect = ({options, value, ...props}: GuildSelectProps) => {

    const filterOption = (value: string, option: any) => {
        const guild = options.find(m => m.id === option.key);
        return !!(guild && ((guild.name && guild.name?.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                || guild.id.indexOf(value) >= 0));
    };

    return (
        <Select showSearch allowClear placeholder='Select a Server'
                filterOption={filterOption} value={value ?? undefined} {...props}>
            {options.map(guild => (
                <Select.Option key={guild.id} value={guild.id}>
                    <GuildName guild={guild} select/>
                </Select.Option>
            ))}
        </Select>
    )
};

export default GuildSelect;

import discordEmojiMap from '../stores/discordEmojiMap.json';

export interface EmojiDefinition {
    primaryName: string;
    primaryNameWithColons: string;
    names: string[];
    namesWithColons: string[];
    surrogates: string;
    utf32codepoints: number[];
    assetFileName: string;
    assetUrl: string;
}

export interface EmojiResult {
    name: string;
    value: string;
}

export const searchEmojis = (query: string) => {
    const result: EmojiResult[] = [];
    discordEmojiMap.emojiDefinitions.forEach(ed => {
        for (const name of ed.namesWithColons) {
            if (name.indexOf(query) >= 0) {
                result.push({
                    name: ed.primaryName,
                    value: ed.surrogates
                });
                break;
            }
        }
    });

    return result;
};

import * as React from "react";
import {observer} from "mobx-react";
import Loader from "../stores/util/Loader";
import {Spin} from "antd";
import {ReactNode} from "react";

interface LoadingWrapperProps {
    loader: Loader;
    children: ReactNode;
}

export const LoadingWrapper = observer(({loader, children}: LoadingWrapperProps) => {
    return (
        <div>
            {loader.isLoading ?
                <Spin/>
                :
                children
            }
        </div>
    )
});

export default LoadingWrapper;

import * as React from "react";
import {Typography} from 'antd';
import {TitleProps} from "antd/es/typography/Title";
import {ReactNode} from "react";

interface HeaderProps extends TitleProps {
    subtitle?: ReactNode;
}

export const Header = ({title, subtitle,...props}: HeaderProps) => {
    return (
        <Typography.Title className='header' {...props}>
            <div>{title}</div>
            {subtitle &&
            <Typography.Text type='secondary'>
                {subtitle}
            </Typography.Text>
            }
        </Typography.Title>
    )
};

export default Header;

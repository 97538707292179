import {action, computed, observable} from "mobx";
import CommandGroup from "./models/CommandGroup";
import api from "../services/API";
import Command from "./models/Command";

interface GroupMap {
    [name: string]: CommandGroup
}

export class CommandStore {
    @observable groups: GroupMap = {};
    @observable currentGroupName: string = "";

    @action
    async fetchCommands() {
        const {data} = await api.get('info/commands');
        const groups: { [name: string]: any[] } = {};
        data.forEach((cmd: any) => {
            const grp = cmd.group.toLowerCase();
            if (!groups[grp]) {
                groups[grp] = []
            }
            groups[grp].push(cmd);
        });

        Object.entries(groups).forEach(([name, commands]) => {
            if (name.toLowerCase() !== 'owner' && name.toLowerCase() !== 'utility' && name.toLowerCase() !== 'commands') {
                this.groups[name] = new CommandGroup({name, commands});
            }
        });

        this.currentGroupName = Object.keys(this.groups)[0];
    }

    async pushCommandToServer(guildId: string, command: Command) {
        await api.put(`guilds/${guildId}/settings`, {
            [`cmd-${command.name.toLowerCase()}`]: command.enabled
        });
    }

    async pushGroupToServer(guildId: string, group: CommandGroup) {
        console.debug(group);
        await api.put(`guilds/${guildId}/settings`, {
            [`grp-${group.name.toLowerCase()}`]: group.enabled
        });
    }

    @action
    setCurrentGroup(group: string) {
        this.currentGroupName = group;
    }

    @computed
    get currentGroup() {
        return this.groups[this.currentGroupName];
    }

    findByName(name: string) {
        for (const group of Object.values(this.groups)) {
            const command = group.commands[name];
            if (command) return command;
        }
    }
}

export default CommandStore;

import {ResultStatusType} from "antd/es/result";
import config from "../app.config"

export interface ServerError {
    title?: string;
    message?: string;
    status: ResultStatusType;
    supportLink?: string;
}

export const ERRORS = {
    NO_SERVERS: <ServerError> {
        status: 'warning',
        title: "You do not have permission to modify the settings of any guilds that Purity is a part of.",
        message: "If you believe this is incorrect, please check that one of the following is true:\n\n" +
            " - You are the server owner.\n" +
            " - You have the Administrator permission in the server AND empowerAdmins is turned on in the bot settings.\n" +
            " - You have the bot's configured adminRole.\n\n" +
            "If one of those is true, also make sure that Purity is in your server and you can run the !points or !profile command."
    },
    INTERNAL_SERVER_ERROR: <ServerError> {
        status: 'error',
        title: "Internal Server Error"
    },
    MISSING_PERMISSONS: <ServerError> {
        status: 'error',
        title: "Missing Permissions",
        message: "Make sure you are the server owner, have ADMINISTRATOR permissions, or have the configured admin role. " +
            "If you are not the server owner but are an admin, empowerAdmins needs to be turned on."
    },
    GUILD_NOT_FOUND: <ServerError> {
        status: 'error',
        title: "Server Not Found",
        message: "If you think this is a mistake, make sure Purity is in your server."
    },
    SERVER_DOWN: <ServerError> {
        status: 'error',
        title: "Server Down",
        message: "The server is currently not responding.",
        supportLink: config.supportDiscord
    }
};

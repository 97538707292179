import * as React from "react";
// @ts-ignore
import {toHTML} from "discord-markdown";
import User from "../stores/models/User";
import Guild from "../stores/models/Guild";

interface MessagePreviewProps {
    className?: string;
    message?: string;
    user?: User;
    guild?: Guild;
}

export const MessagePreview = ({className, message = '', user, guild}: MessagePreviewProps) => {
    if (user) {
        message = message.replace(/@user/g, `<@${user.id}>`)
    }

    if (guild) {
        message = message.replace(/@guild/g, guild.name);
    }

    message = message.replace(/@level/g, '42');

    const html = toHTML(message, {
        embed: true, discordCallback: {
            user: () => `@${user?.username}`,
            channel: ({id}: any) => `#${guild?.channels.find(c => c.id === id)?.name ?? id}`,
            role: ({id}: any) => `@${guild?.roles.find(r => r.id === id)?.name ?? id}`,
        }
    });
    return (
        <div className={`message-preview ${className ?? ''}`} dangerouslySetInnerHTML={{__html: html}}/>
    )
};

export default MessagePreview;

import {action, computed, observable} from "mobx";

export class TicketType {
    id?: number;
    guildId?: string;
    @observable channelId?: string;
    @observable topic?: string;
    readonly questions = observable<string>([]);

    constructor(json?: any) {
        if (json) {
            this.update(json);
        }
    }

    @action
    update(json: any) {
        this.id = json.id;
        this.guildId = json.guildId;
        this.channelId = json.channelId;
        this.topic = json.topic;
        this.setQuestions(json.questions);
    }

    @action
    setChannelId(channelId: string) {
        this.channelId = channelId;
    }

    @action
    setTopic(topic: string) {
        this.topic = topic;
    }

    @action
    setQuestions(questions: string[]) {
        this.questions.replace(questions);
    }

    @action
    addQuestion(question: string) {
        this.questions.push(question);
    }

    @action
    removeQuestion(index: number) {
        this.questions.splice(index, 1);
    }

    @computed
    get isValid() {
        return this.topic && this.topic.length > 0
            && this.channelId && this.channelId.length > 0;
    }

}

import * as React from "react";
import {Button, Form, Result, Spin, Typography} from "antd";
import {ModalProps} from "antd/es/modal";
import {PaymentForm} from "../components/PaymentForm";
import api from "../../../services/API";
import Loader from "../../../stores/util/Loader";
import GuildSelect from "../../../components/GuildSelect";
import {StoreContext} from "../../../stores/Stores";
import {RouteComponentProps, withRouter} from "react-router";
import Routes, {guildRoute} from "../../../Routes";
import {observer} from "mobx-react";
import displayPlan from "../../../util/displayPlan";
import Subscription from "../models/Subscription";
import PaymentDetails from "../models/PaymentDetails";

interface CheckoutState {
    guildId?: string;
    paymentDetails?: PaymentDetails;
    subscriptions?: any[];
    subscribedGuilds: { [id: string]: boolean };
    complete?: boolean;
    plan?: { amount: number, currency: string, interval: string };
}

interface CheckoutProps extends ModalProps {
    plan: string;
    complete?: boolean;
    onSubscriptionChange: (subscription: Subscription) => void;
    onComplete?: () => void;
}

export const Checkout = withRouter(observer(class extends React.Component<CheckoutProps & RouteComponentProps, CheckoutState> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    state: CheckoutState = {subscribedGuilds: {}};

    componentDidMount(): void {
        this.loader.load(Promise.all([
            this.fetchPlan(),
            this.fetchSubscriptions()
        ]))
    }

    fetchPlan = async () => {
        const {data} = await api.get(`premium/plans/${this.props.plan}`);
        this.setState({plan: data});
    };

    fetchSubscriptions = async () => {
        const {data} = await api.get(`premium/subscriptions`);
        const subscribedGuilds: { [id: string]: boolean } = {};
        data.forEach((s: any) => {
            if (s.status === 'active' || s.status === 'incomplete') {
                subscribedGuilds[s.metadata.guildId] = true;
            }
        });
        this.setState({subscriptions: data, subscribedGuilds});

        if (this.props.onComplete && !this.context.domainStore.guilds.some(g => !this.state.subscribedGuilds[g.id])) {
            this.props.onComplete();
        }
    };

    changePaymentMethod = (paymentDetails: PaymentDetails) => {
        const {paymentMethod, billingDetails} = paymentDetails;
        this.setState({paymentDetails});
        this.props.onSubscriptionChange({
            paymentMethod,
            billingDetails,
            guildId: this.state.guildId,
            plan: this.props.plan
        })
    };

    changeGuild = (guildId: string) => {
        this.setState({guildId});
        this.props.onSubscriptionChange({
            paymentMethod: this.state.paymentDetails?.paymentMethod,
            billingDetails: this.state.paymentDetails?.billingDetails,
            guildId: guildId,
            plan: this.props.plan
        })
    };

    goToDashboard = () => {
        this.props.history.push(guildRoute(Routes.guild, this.state.guildId));
    };

    render() {
        const guildOptions = this.context.domainStore.guilds.filter(g => !this.state.subscribedGuilds[g.id]);
        const guild = this.context.domainStore.guilds.find(g => g.id === this.state.guildId);
        const plan = displayPlan(this.state.plan);
        const canAddSubscription = guildOptions.length > 0;

        return (
            <div>
                {canAddSubscription &&
                <>
                    {!this.props.complete &&
                    <Spin spinning={this.loader.showLoader}>
                        {this.state.plan &&
                        <div style={{marginBottom: 20}}>
                          <Typography.Text>You will automatically be billed <Typography.Text
                              strong>{plan}</Typography.Text>.</Typography.Text>
                        </div>
                        }
                      <Form labelAlign='left'>
                        <Form.Item label='Server' labelCol={{span: 24}}
                                   extra="Choose the server you would like to add premium to.">
                          <GuildSelect options={guildOptions} value={this.state.guildId} onChange={this.changeGuild}/>
                        </Form.Item>
                      </Form>

                      <PaymentForm onChange={this.changePaymentMethod}/>
                    </Spin>
                    }

                    {this.props.complete &&
                    <Result status='success' title={`Successfully Purchased Premium for ${guild?.name}!`}
                            subTitle='It may take a few minutes for premium status to take effect.'
                            extra={<Button type='primary' onClick={this.goToDashboard}>Go to Dashboard</Button>}
                    />
                    }
                </>
                }

                {!canAddSubscription &&
                <Result status='info' title='All Servers Have Premium'
                        subTitle='Congrats! All your servers already have premium. If you would like to update a subscription, first cancel it and then re-add it.'
                        extra={<Button type='primary' onClick={this.goToDashboard}>Go to Dashboard</Button>}
                />
                }
            </div>
        )
    }
}));

export default Checkout;

import * as React from "react";
import {Card, Col, List, Row} from "antd";
import GiveawayCard from "./components/GiveawayCard";
import {observer} from "mobx-react";
import AddGiveaway from "./scenes/AddGiveaway";
import Loader from "../../stores/util/Loader";
import {GuildComponentProps} from "../../types/GuildComponentProps";

@observer
export class Giveaways extends React.Component<GuildComponentProps> {

    loader = new Loader();
    optionsLoader = new Loader();

    componentDidMount() {
        this.fetchOptions();
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.fetchOptions();
        }
    }

    fetchOptions() {
        const {guild} = this.props;
        if (guild && guild.roles.length <= 0) {
            this.loader.load(guild.fetchGiveaways());
            this.optionsLoader.load(this.initOptions());
        }
    }

    async initOptions() {
        const {guild} = this.props;
        if (guild) {
            if (guild.roles.length <= 0) {
                await guild.fetchRoles();
            }
            if (guild.channels.length <= 0) {
                await guild.fetchChannels();
            }
        }
    }

    render() {
        const {guild} = this.props;

        return (
            <Row gutter={10}>
                <Col span={12}>
                    <AddGiveaway guild={guild}/>
                </Col>
                <Col span={12}>
                    <Card title="Giveaways" style={{textAlign: "left"}} loading={this.loader.showLoader || this.optionsLoader.showLoader}>
                        <List dataSource={guild.giveaways.toJS()} renderItem={giveaway => {
                            return (
                                <List.Item>
                                    <GiveawayCard giveaway={giveaway} guild={guild}/>
                                </List.Item>
                            )
                        }}>
                        </List>
                    </Card>
                </Col>
            </Row>
        )
    }
};

export default Giveaways;

import * as React from "react";
import Guild from "../stores/models/Guild";
import {Button, Result} from "antd";
import Routes from "../Routes";
import {RouteComponentProps} from "react-router";

interface PremiumWrapperProps extends RouteComponentProps {
    guild: Guild;
    component: typeof React.Component;
}

export const PremiumWrapper = ({guild, component: Component, ...props}: PremiumWrapperProps) => {
    if (guild.isPremium) {
        return <Component guild={guild}/>;
    }

    return (
        <Result title='You must have premium in order to use this feature.'
                extra={
                    <Button type='primary' onClick={() => props.history.push(Routes.premium)} disabled>
                        Get Premium!
                    </Button>
                }>
        </Result>
    )
};

export default PremiumWrapper;

import {CardElement, injectStripe, ReactStripeElements} from "react-stripe-elements";
import React, {ChangeEventHandler, Component, FormEventHandler} from "react";
import {Alert, Button, Form, Input} from "antd";

interface CardFormState {
    errorMessage: string;
    name: string;
}

interface CardFormProps {
    handleResult: (response: stripe.PaymentMethodResponse) => void;
    onCancel?: () => void;
}

export const CardForm = injectStripe(class extends Component<CardFormProps & ReactStripeElements.InjectedStripeProps, CardFormState> {
    state: CardFormState = {
        errorMessage: '',
        name: ''
    };

    handleChange = ({error}: any) => {
        if (error) {
            this.setState({errorMessage: error.message});
        } else {
            this.setState({errorMessage: ''});
        }
    };

    handleSubmit = async () => {
        if (this.props.stripe) {
            const response = await this.props.stripe.createPaymentMethod('card', {
                billing_details: {
                    name: this.state.name
                }
            });

            this.props.handleResult(response);
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    changeName: ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({name: e.target.value})
    };

    render() {
        return (
            <div className="card-form">
                <Form onFinish={this.handleSubmit.bind(this)}>
                    <Form.Item required label='Name on Card' labelCol={{span: 24}}>
                        <Input placeholder='Wumpus' value={this.state.name} onChange={this.changeName}/>
                    </Form.Item>

                    <Form.Item required label='Card details' labelCol={{span: 24}}>
                        <CardElement className='ant-input'
                            onChange={this.handleChange}
                            {...createOptions()}
                        />
                        {(this.state.errorMessage &&
                        <Alert type='error' message={this.state.errorMessage}/>) as any
                        }
                    </Form.Item>

                    <Button htmlType='button' onClick={this.props.onCancel} style={{marginRight: 10}}>Cancel</Button>
                    <Button type='primary' htmlType='submit'>Add Payment Method</Button>
                </Form>
            </div>
        );
    }
});

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#ffffffd9',
                fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial,\n' +
                    '  \'Noto Sans\', sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\',\n' +
                    '  \'Noto Color Emoji\'',
                padding: '10px'
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

export default CardForm;

import * as React from "react";
import {Result} from "antd";
import {ServerError} from "../types/ServerError";
import {ReactNode} from "react";

export const Error = ({error}: {error: ServerError}) => {
    const {status, title, message} = error;
    let subTitle: string | ReactNode = message;
    if (error.supportLink) {
        subTitle = <div>{message} Join our <a href={error.supportLink} target='_blank'>Support Server</a> for more information.</div>
    }

    return (
        <Result status={status} title={title} subTitle={subTitle} style={{whiteSpace: 'pre-line'}}/>
    )
};

export default Error;

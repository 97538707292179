import * as React from "react";
import {Select} from "antd";
import {SelectProps} from "antd/es/select";
import {EmojiResult, searchEmojis} from "../services/searchEmojis";
import Guild from "../stores/models/Guild";
import Emoji from "./Emoji";

interface EmojiSelectProps extends Omit<SelectProps<string>, 'value'> {
    guild?: Guild;
    value?: string | null;
}

interface EmojiSelectState {
    results: EmojiResult[];
}

export class EmojiSelect extends React.Component<EmojiSelectProps> {

    state: EmojiSelectState = {
        results: this.props.guild?.emojis.map(e => {
            return {
                name: e.name,
                value: e.id
            }
        }) ?? []
    };

    searchTimeout: any = null;

    searchGuildEmojis = (query: string) => {
        return this.props.guild?.emojis
            .filter(e => e.name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                || e.id.indexOf(query) > 0)
            .map(e => {
                return {
                    name: e.name,
                    value: e.id
                }
            }) ?? [];
    };

    searchAllEmojis = (value: string) => {
        let query = value;
        if (value.startsWith(':')) {
            query = value.substring(1);
        }
        let results: EmojiResult[] = [];
        if (query.length > 1) {
            if (this.props.guild) {
                results = results.concat(this.searchGuildEmojis(query));
            }
            results = results.concat(searchEmojis(query)).slice(0, 50); // too many results makes dropdown react slowly
        }

        this.setState({results});
    };

    handleSearch = (value: string) => {
        // keep quick typing from doing too many searches
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }
        this.searchTimeout = setTimeout(() => {
            this.searchAllEmojis(value);
        }, 100);
    };

    render() {
        const {guild, value, ...props} = this.props;
        return (
            <Select placeholder=':Emoji:'
                    showSearch
                    allowClear
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    optionLabelProp='label'
                    onSearch={this.handleSearch}
                    style={{minWidth: '100%'}}
                    value={value ?? undefined}
                    {...props}>
                {
                    this.state.results.map(r => {
                        const emoji = <Emoji emoji={r.value} guild={this.props.guild}/>;
                        return (
                            <Select.Option key={r.value} value={r.value}
                                           label={<span className='select-label'>{emoji}</span>}>
                                {emoji} :{r.name}:
                            </Select.Option>
                        )
                    })
                }
            </Select>
        )
    }
}

export default EmojiSelect;

import {action, observable} from "mobx";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import moment from "moment";
import api from "./API";
import stores from "../stores/Stores";
import Routes from "../Routes";

type DiscordAuth = {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
}

type Token = {
    sub: string;
    auth: DiscordAuth
    exp: number;
    iat: number;
}

type AuthResponse = {
    profile: any;
    token: string;
}

export class Authenticator {
    @observable token?: string | null = Cookies.get('access_token');

    async login(code: string) {
        const redirect_uri = encodeURIComponent(window.location.origin + Routes.login);
        const {data} = await api.get<AuthResponse>(`auth/discord?code=${code}&redirect_uri=${redirect_uri}`);
        this.setToken(data.token);
        return data;
    }

    @action
    private setToken(token: string) {
        this.token = token;
        Cookies.set('access_token', token);
    }

    @action
    logout() {
        console.debug('LOGGING OUT');
        this.invalidateToken();
        stores.clear();
        window.location.href = "https://puritybot.com";
    }

    invalidateToken() {
        this.token = null;
        Cookies.remove('access_token');
    }

    verifyToken() {
        return Authenticator.verifyToken(this.token);
    }

    static parseJWT(token: string) {
        return jwt.decode(token) as Token;
    }

    static verifyToken(token?: string | null) {
        if (!token) {
            return false;
        }

        const {exp} = this.parseJWT(token);
        return exp > moment().unix();
    }
}

export const auth = new Authenticator();

export default Authenticator;

export class Pet {
    id!: number;
    guildId!: string;
    userId!: string;
    type!: string;
    name!: string;
    gender!: string;
    weight!: number;
    height!: number;
    exp!: number;
    level!: number;
    maxHealth!: number;
    health!: number;
    hunger!: number;
    happiness!: number;
    attack!: number;
    defense!: number;
    speed!: number;
    dream!: string;
    specialAttack!: string;
    favoriteFood!: string;
    favoriteActivity!: string;
    inventory!: object;

    constructor(json: Pet) {
        this.id = json.id;
        this.guildId = json.guildId;
        this.userId = json.userId;
        this.type = json.type;
        this.name = json.name;
        this.gender = json.gender;
        this.weight = json.weight;
        this.height = json.height;
        this.exp = json.exp;
        this.level = json.level;
        this.maxHealth = json.maxHealth;
        this.health = json.health;
        this.hunger = json.hunger;
        this.happiness = json.happiness;
        this.attack = json.attack;
        this.defense = json.defense;
        this.speed = json.speed;
        this.dream = json.dream;
        this.specialAttack = json.specialAttack;
        this.favoriteFood = json.favoriteFood;
        this.favoriteActivity = json.favoriteActivity;
        this.inventory = json.inventory;
    }
}

export default Pet;

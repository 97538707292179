import * as React from "react";
import {Card, InputNumber, message} from "antd";
import Loader from "../../stores/util/Loader";
import {observer, Observer} from "mobx-react";
import Rank from "../../stores/models/Rank";
import RoleSelect from "../../components/RoleSelect";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import Role from "../../components/Role";
import Header from "../../components/Header";
import EditableTable from "../../components/EditableTable";

@observer
export class Ranks extends React.Component<GuildComponentProps> {

    loader = new Loader();
    rankToAdd = new Rank({id: "new", editing: true, isNew: true});

    componentDidMount() {
        this.loader.load(this.fetchOptions());
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.loader.load(this.fetchOptions());
        }
    }

    async fetchOptions() {
        await this.props.guild.fetchRoles();
        await this.props.guild.fetchRanks();
    }

    onChangeRole(rank: Rank) {
        return (roleId: string) => {
            const role = this.props.guild.roles.find(r => r.id === roleId);
            rank.setRole(role);
        };
    }

    onChangeReqRole(rank: Rank) {
        return (roleId: string) => {
            rank.setReqRoleId(roleId);
        };
    }

    onChangeReqLevel(rank: Rank) {
        return (level?: string | number) => {
            if (typeof level === 'string') {
                level = parseInt(level);
            }
            rank.setReqLevel(level);
        };
    }

    onChangeReqCurrency(rank: Rank) {
        return (ammount?: string | number) => {
            if (typeof ammount === 'string') {
                ammount = parseInt(ammount);
            }
            rank.setReqCurrency(ammount);
        };
    }

    deleteRank = async (rank: Rank) => {
        try {
            await this.props.guild.deleteRank(rank.id!);
            message.success('Deleted rank');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    updateRank = async (rank: Rank) => {
        try {
            await this.props.guild.updateRank(rank);
            message.success('Updated rank');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    submitRank = async () => {
        try {
            await this.loader.load(this.props.guild.createRank(this.rankToAdd));
            this.rankToAdd.update({id: 'new', editing: true, isNew: true}); // clear the rank to add
            message.success('Added rank!')
        } catch (e) {
            console.error(e);
            message.error('Something went wrong');
        }
    };

    static rankSorter(field: keyof Rank) {
        return (a: Rank, b: Rank) => {
            if (a.isNew || b.isNew) return 0;
            if (!a[field] && !b[field]) return 0;
            if (a[field] && !b[field]) return 1;
            if (b[field] && !a[field]) return -1;
            if (typeof a[field] === "string") {
                return (a[field] as string)!.localeCompare(b[field] as string);
            } else if (typeof a[field] === "number") {
                return (a[field] as number) - (b[field] as number);
            }
            return 0;
        }
    }

    render() {
        const {guild} = this.props;
        const loading = this.loader.isLoading;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 2,
                sorter: Ranks.rankSorter('id'),
                render: (id: string) => id === "new" ? "" : id
            },
            {
                title: 'Role',
                dataIndex: 'roleId',
                key: 'roleId',
                width: 6,
                sorter: Ranks.rankSorter('roleId'),
                render: (roleId: string, rank: Rank) => {
                    if (rank.editing) {
                        return (
                            <Observer>{() => (
                                <RoleSelect disabled={loading} options={guild.roles}
                                            value={rank.roleId} onChange={this.onChangeRole(rank)}/>
                            )}</Observer>
                        )
                    }
                    const role = guild.findRoleByID(roleId);
                    return role ? <Role role={role}/> : roleId;
                }
            },
            {
                title: 'Required Level',
                dataIndex: 'reqLevel',
                key: 'reqLevel',
                width: 4,
                render: (reqLevel: any, rank: Rank) => {
                    if (rank.editing) {
                        return (
                            <Observer>{() => (
                                <InputNumber disabled={loading} min={0}
                                             value={rank.reqLevel} onChange={this.onChangeReqLevel(rank)}/>
                            )}</Observer>
                        )
                    }
                    if (reqLevel === 0 || reqLevel == null) {
                        return "None"
                    }
                    if (typeof reqLevel === 'number') {
                        return reqLevel.toLocaleString()
                    }
                    return reqLevel;
                },
                sorter: Ranks.rankSorter('reqLevel')
            },
            {
                title: 'Required Currency',
                dataIndex: 'reqCurrency',
                key: 'reqCurrency',
                width: 4,
                render: (reqCurrency: any, rank: Rank) => {
                    if (rank.editing) {
                        return (
                            <Observer>{() => (
                                <InputNumber disabled={loading} min={0}
                                             value={rank.reqCurrency} onChange={this.onChangeReqCurrency(rank)}/>
                            )}</Observer>
                        )
                    }
                    if (reqCurrency === 0 || reqCurrency == null) {
                        return "None"
                    }
                    if (typeof reqCurrency === 'number') {
                        return reqCurrency.toLocaleString()
                    }
                    return reqCurrency;
                },
                sorter: Ranks.rankSorter('reqCurrency')
            },
            {
                title: 'Required Role',
                dataIndex: 'reqRoleId',
                key: 'reqRoleId',
                width: 6,
                sorter: Ranks.rankSorter('reqRoleId'),
                render: (reqRoleId: string, rank: Rank) => {
                    if (rank.editing) {
                        return (
                            <Observer>{() => (
                                <RoleSelect disabled={loading} options={guild.roles} value={rank.reqRoleId}
                                            onChange={this.onChangeReqRole(rank)}/>
                            )}</Observer>
                        )
                    }
                    if (reqRoleId == null) {
                        return "None"
                    }
                    return guild.findRoleByID(reqRoleId)?.name ?? reqRoleId;
                }
            }
        ];

        return (
            <Card title={<Header title='Ranks' subtitle='Ranks allow users to get certain roles if they have the required level, currency, or existing role.
                A rank will be given automatically if a required level is set but no required currency or existing role.'/>}
                  style={{textAlign: "left"}}>
                <EditableTable bordered columns={columns} pagination={false}
                               dataSource={guild.ranks.toJS().concat([this.rankToAdd])}
                               rowKey="id"
                               onAdd={this.submitRank}
                               onDelete={this.deleteRank}
                               onSave={this.updateRank}/>
            </Card>
        )
    }
}

export default Ranks;

import * as React from "react";
import {MouseEventHandler, ReactNode} from "react";

interface ItemProps {
    value: any;
    onSelect: ({value}: any) => void;
    active?: boolean;
    children: ReactNode;
}

export const TabMenuItem = ({value, active, children, onSelect, ...props}: ItemProps) => {
    const onClick = () => {
        onSelect({value});
    };

    return (
        <li {...props}>
            <a className={active ? 'active' : ''} onClick={onClick}>
                {children}
            </a>
        </li>
    )
};


interface TabMenuProps {
    onSelect?: MouseEventHandler<HTMLAnchorElement>;
    children: ReactNode;
}

export class TabMenu extends React.Component<TabMenuProps> {
    static Item = TabMenuItem;

    render() {
        const {onSelect, children, ...props} = this.props;
        return (
            <ul className='tabs' {...props}>
                {children}
            </ul>
        )
    }
}

export default TabMenu;

import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import Routes from "../Routes";
import {auth} from "../services/Authenticator";

interface PrivateRouteProps extends RouteProps {
    component: typeof React.Component;
    redirect?: boolean;
}

export const PrivateRoute = ({component: Component, redirect = true, ...rest}: PrivateRouteProps) => (
    <Route {...rest} render={(props) => (
        auth.verifyToken() ?
            <Component {...props} />
            :
            redirect ?
                <Redirect to={{
                    pathname: Routes.login,
                    state: {from: props.location}
                }}/>
                :
                <div/>
    )}/>
);

export default PrivateRoute;

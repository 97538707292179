import * as React from "react";
import {ChangeEventHandler} from "react";
import SettingItem from "../../components/SettingItem";
import {Button, Card, Col, Input, InputNumber, message, Row, Switch} from "antd";
import Loader from "../../stores/util/Loader";
import {observer} from "mobx-react";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import Help from "../../components/Help";
import {parseNumber} from "../../util/parseNumber";
import RangeInput, {RangeValue} from "../../components/RangeInput";

@observer
export class Economy extends React.Component<GuildComponentProps> {

    loader = new Loader();

    submit = async () => {
        try {
            await this.loader.load(this.props.guild.pushEconomySettingsToServer());
            message.success('Updated Economy settings!')
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    changePointSystem = (checked: boolean) => {
        this.props.guild.settings.setPointSystem(checked);
    };

    changeCurrencyName: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        this.props.guild.settings.setCurrencyName(target.value);
    };

    changeDefaultPoints = (value?: number | string) => {
        value = parseNumber(value);
        this.props.guild.settings.setDefaultPoints(value);
    };

    changeDailyBonus = (value?: number | string) => {
        value = parseNumber(value);
        this.props.guild.settings.setDailyBonus(value);
    };

    changeVoteIncentive = (value?: number | string) => {
        value = parseNumber(value);
        this.props.guild.settings.setVoteIncentive(value);
    };

    changeMessagePoints = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setMessagePoints(min);
        }
        this.props.guild.settings.setMessagePoints(`${min}-${max}`);
    };

    changeWorkMoney = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setWorkMoney(min);
        }
        this.props.guild.settings.setWorkMoney(`${min}-${max}`);
    };

    changeCrimeMoney = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setCrimeMoney(min);
        }
        this.props.guild.settings.setCrimeMoney(`${min}-${max}`);
    };

    changeExploreMoney = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setExploreMoney(min);
        }
        this.props.guild.settings.setExploreMoney(`${min}-${max}`);
    };

    changeFishMoney = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setFishMoney(min);
        }
        this.props.guild.settings.setFishMoney(`${min}-${max}`);
    };

    changeHuntMoney = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setHuntMoney(min);
        }
        this.props.guild.settings.setHuntMoney(`${min}-${max}`);
    };

    changeComplimentMoney = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setComplimentMoney(min);
        }
        this.props.guild.settings.setComplimentMoney(`${min}-${max}`);
    };

    render() {
        const {
            pointSystem, currencyName, defaultPoints, dailyBonus, voteIncentive, messagePoints, workMoney,
            crimeMoney, exploreMoney, fishMoney, huntMoney, complimentMoney
        } = this.props.guild.settings.economySettings;
        return (
            <Card title='Economy' style={{textAlign: "left"}}>

                <SettingItem
                    label={<Help label='Point System' help='If turned off, members will not gain points or xp.'/>}
                    inline>
                    <Switch checked={pointSystem} onChange={this.changePointSystem}/>
                </SettingItem>

                <SettingItem label='Currency Name'>
                    <Input value={currencyName} onChange={this.changeCurrencyName}/>
                </SettingItem>
                <Row>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem
                            label={<Help label='Default Points' help='The number of points someone will start with.'/>}>
                            <InputNumber value={defaultPoints} onChange={this.changeDefaultPoints}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem label={<Help label='Daily Bonus'
                                                  help={`The number of points someone will get for using ${this.props.guild.settings.prefix}daily.`}/>}>
                            <InputNumber value={dailyBonus} onChange={this.changeDailyBonus}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem label={<Help label='Vote Incentive'
                                                  help='The number of points someone will get for voting for Purity.'/>}>
                            <InputNumber value={voteIncentive} onChange={this.changeVoteIncentive}/>
                        </SettingItem>
                    </Col>
                </Row>

                <Row>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Message Points' help='The number of points someone will get each message they send.
                Purity will ignore 1-word messages and points can only be gained every 5 seconds.'/>}>
                            <RangeInput value={messagePoints} min={0} max={10000} onChange={this.changeMessagePoints}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Work Points'
                                                                    help='The number of points someone will get from using the !work command.'/>}>
                            <RangeInput value={workMoney} min={1} max={10000} onChange={this.changeWorkMoney}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Crime Points'
                                                                    help='The number of points someone will get from using the !crime command.'/>}>
                            <RangeInput value={crimeMoney} min={1} max={10000} onChange={this.changeCrimeMoney}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Explore Points'
                                                                    help='The number of points someone will get from using the !explore command.'/>}>
                            <RangeInput value={exploreMoney} min={1} max={10000} onChange={this.changeExploreMoney}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Fish Points'
                                                                    help='The number of points someone will get from using the !fish command.'/>}>
                            <RangeInput value={fishMoney} min={1} max={10000} onChange={this.changeFishMoney}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Hunt Points'
                                                                    help='The number of points someone will get from using the !hunt command.'/>}>
                            <RangeInput value={huntMoney} min={1} max={10000} onChange={this.changeHuntMoney}/>
                        </SettingItem>
                    </Col>
                    <Col xl={8} md={12} sm={24}>
                        <SettingItem wrapperWidth={24} label={<Help label='Compliment Points'
                                                                    help='The number of points someone will get from using the !compliment command.'/>}>
                            <RangeInput value={complimentMoney} min={1} max={10000} onChange={this.changeComplimentMoney}/>
                        </SettingItem>
                    </Col>
                </Row>

                <Button type='primary' onClick={this.submit} loading={this.loader.isLoading}>Save</Button>
            </Card>
        )
    }
}

export default Economy;

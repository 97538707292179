import {action, computed, observable} from "mobx";
import Pet from "./Pet";
import User from "./User";
import DiscordGuildMember from "../types/DiscordGuildMember";
import moment, {Moment} from "moment";
import api from "../../services/API";

export class GuildMember implements DiscordGuildMember {
    guildId!: string;
    userId!: string;
    @observable _points!: number;
    exp!: number;
    level!: number;
    @observable birthday?: Moment | null;
    user!: User;
    pets: Pet[] = [];
    //Discord Member
    deaf: boolean;
    joined_at: string;
    mute: boolean;
    nick: string;
    premium_since: string;
    roles: string[];

    constructor(json: any) {
        this.guildId = json.guildId;
        this.userId = json.userId ?? json.user?.id;
        this._points = json._points;
        this.exp = json.exp;
        this.level = json.level;
        this.birthday = json.birthday ? moment(json.birthday) : json.birthday;
        // Discord Member
        this.deaf = json.deaf;
        this.joined_at = json.joined_at;
        this.mute = json.mute;
        this.nick = json.nick;
        this.premium_since = json.premium_since;
        this.roles = json.roles;

        if (json.user) {
            this.user = new User(json.user);
        }

        if (json.pets) {
            this.pets = json.pets.map((pet: any) => new Pet(pet));
        }
    }

    @action setPoints(points: number) {
        this._points = points;
        // Max int
        if (this._points > 2147483647) {
            this._points = 2147483647;
        }

        if (this._points < 0) {
            this._points = 0
        }
    }

    @computed get points() {
        return this._points;
    }

    @action
    setBirthday(birthday?: Moment | null) {
        this.birthday = birthday;
    }

    get displayName() {
        return this.nick ?? this.user.username;
    }

    async pushBirthdayToServer() {
        if (this.birthday) {
            return api.put(`guilds/${this.guildId}/birthdays/${this.userId}`, {birthday: this.birthday});
        } else {
            return api.delete(`guilds/${this.guildId}/birthdays/${this.userId}`);
        }
    }
}

export default GuildMember;

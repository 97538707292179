import * as React from "react";
import CardIcon from "../../Twitch/components/CardIcon";
import {Typography} from "antd";

const brandDisplay: any = {
    'amex': 'American Express',
    'diners': 'Diners Club',
    'discover': 'Discover',
    'jcb': 'JCB',
    'mastercard': 'MasterCard',
    'visa': 'Visa',
};

interface PaymentMethodProps {
    method: any;
}

export const PaymentMethod = ({method}: PaymentMethodProps) => {
    const {brand, last4} = method.card!;
    return (
        <span>
            <CardIcon brand={brand}/>
            <Typography.Text strong>
                {brandDisplay[brand] ?? brand}
            </Typography.Text>
            <Typography.Text type='secondary'>
                {` ending in ${last4}`}
            </Typography.Text>
        </span>
    )
};

export default PaymentMethod;

import * as React from "react";
import {ChangeEventHandler} from "react";
import {Col, Form, Input, InputNumber, Row} from "antd";
import EmojiSelect from "../../../components/EmojiSelect";
import TextArea from "antd/es/input/TextArea";
import RoleSelect from "../../../components/RoleSelect";
import {GuildItem} from "../../../stores/models/GuildItem";
import Guild from "../../../stores/models/Guild";
import {observer} from "mobx-react";
import Help from "../../../components/Help";

interface GuildItemFormProps {
    item: GuildItem;
    guild: Guild;
}

@observer
export class GuildItemForm extends React.Component<GuildItemFormProps> {

    changeEmoji = (id: string) => {
        this.props.item.setEmoji(id);
    };

    changeName: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        this.props.item.setName(target.value);
    };

    changeDescription: ChangeEventHandler<HTMLTextAreaElement> = ({target}) => {
        this.props.item.setDescription(target.value);
    };

    changePrice = (price?: number | string) => {
        if (typeof price === "string") {
            price = parseInt(price);
        }
        this.props.item.setReqCurrency(price);
    };

    changeQty = (qty?: number | string) => {
        if (typeof qty === "string") {
            qty = parseInt(qty);
        }
        this.props.item.setQty(qty);
    };

    changeUserLimit = (userLimit?: number | string) => {
        if (typeof userLimit === "string") {
            userLimit = parseInt(userLimit);
        }
        this.props.item.setLimitForUser(userLimit);
    };

    changeReqLevel = (reqLevel?: number | string) => {
        if (typeof reqLevel === "string") {
            reqLevel = parseInt(reqLevel);
        }
        this.props.item.setReqLevel(reqLevel);
    };

    changeReqRole = (role?: string) => {
        this.props.item.setReqRoleId(role);
    };

    render() {
        const {item, guild} = this.props;
        return (
            <Form layout='vertical'>
                <Row gutter={10}>
                    <Col span={24} lg={12}>
                        <Row gutter={10}>
                            <Col xs={24} md={6}>
                                <Form.Item label="Emoji">
                                    <EmojiSelect guild={guild} value={item.emoji} onChange={this.changeEmoji}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={18}>
                                <Form.Item label="Name" required>
                                    <Input value={item.name} onChange={this.changeName}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item label="Description">
                            <TextArea value={item.description ?? ''} onChange={this.changeDescription}/>
                        </Form.Item>
                    </Col>
                    <Col span={24} lg={12}>
                        <Row gutter={10}>
                            <Col xs={24} md={8}>
                                <Form.Item label="Price">
                                    <InputNumber min={0} max={2147483647} value={item.reqCurrency ?? 0}
                                                 onChange={this.changePrice}/>
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={8}>
                                <Form.Item label={<Help label='User Limit' help='Set to -1 for no limit.'/>}>
                                    <InputNumber min={-1} max={2147483647} value={item.limitForUser ?? -1}
                                                 onChange={this.changeUserLimit}/>
                                </Form.Item>
                            </Col>
                            <Col xs={12} md={8}>
                                <Form.Item label={<Help label='Quantity' help='The total number of this item that is available. Set to -1 for no limit.'/>}>
                                    <InputNumber min={-1} max={2147483647} value={item.qty ?? -1}
                                                 onChange={this.changeQty}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={24} md={8}>
                                <Form.Item label={<Help label='Required Level' help='Set to -1 for none.'/>}>
                                    <InputNumber min={-1} max={2147483647} value={item.reqLevel ?? -1}
                                                 onChange={this.changeReqLevel}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8} lg={16} xl={8}>
                                <Form.Item label="Required Role">
                                    <RoleSelect options={guild.roles} value={item.reqRoleId}
                                                onChange={this.changeReqRole}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default GuildItemForm;

import * as React from "react";
import UserAvatar from "../../components/UserAvatar";
import {StoreContext} from "../../stores/Stores";
import {Card, Form, message, Spin, Typography} from "antd";
import Loader from "../../stores/util/Loader";
import api from "../../services/API";

interface ProfileState {
    customer?: any;
}

export class Profile extends React.Component<any, ProfileState> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    state: ProfileState = {};

    componentDidMount() {
        this.loader.load(this.fetchCustomer());
    }

    async fetchCustomer() {
        const {data} = await api.get('premium/customer');
        if (data) {
            this.setState({customer: data});
        }
    }

    changeName = async (name: string) => {
        const customer = this.state.customer;
        if (name === customer.name) {
            return;
        }
        // Test if valid
        if (name.length > 0) {
            try {
                await this.loader.load(api.put('premium/customer', {name}));
                customer.name = name;
                this.setState({customer});
            } catch (e) {
                message.error('Invalid name.');
            }
        } else {
            message.error('Invalid name.');
        }
    };

    changeEmail = async (email: string) => {
        const customer = this.state.customer;
        if (email === customer.email) {
            return;
        }

        // Test if valid
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            try {
                await this.loader.load(api.put('premium/customer', {email}));
                customer.email = email;
                this.setState({customer});
            } catch (e) {
                message.error('Invalid email address.');
            }
        } else {
            message.error('Invalid email address.');
        }
    };

    render() {
        const {user} = this.context.userStore;

        if (!user) return <Spin/>;

        return (
            <Card className='profile'>
                <UserAvatar user={user} size='large'/>
                <Typography.Title>
                    {user.username}
                    <Typography.Text type='secondary'>#{user.discriminator}</Typography.Text>
                </Typography.Title>
                {this.state.customer &&
                <Form>
                  <Typography.Title level={2} underline>Billing Details</Typography.Title>
                  <Form.Item label='Name' labelCol={{span: 6}} wrapperCol={{span: 18}}>
                    <Typography.Text editable={{onChange: this.changeName}}>{this.state.customer.name}</Typography.Text>
                  </Form.Item>
                  <Form.Item label='Email' labelCol={{span: 6}} wrapperCol={{span: 18}}>
                    <Typography.Text editable={{onChange: this.changeEmail}}>{this.state.customer.email}</Typography.Text>
                  </Form.Item>
                </Form>
                }

            </Card>
        )
    }
}

export default Profile;

import React, {ChangeEventHandler, Component} from 'react';
import {Elements,} from 'react-stripe-elements';
import {Button, Col, Form, Input, Row} from "antd";
import PaymentMethodList from "./PaymentMethodList";
import api from "../../../services/API";
import CardForm from "./CardForm";
import PaymentDetails from "../models/PaymentDetails";
import {PlusOutlined} from '@ant-design/icons';

interface CardFormProps {
    onChange: (paymentDetails: PaymentDetails) => void;
}

interface PaymentFormState {
    customer?: any,
    paymentMethods: stripe.paymentMethod.PaymentMethod[],
    paymentMethod?: string,
    name?: string,
    email?: string,
    showCardForm: boolean
}

export class PaymentForm extends Component<CardFormProps, PaymentFormState> {
    state: PaymentFormState = {
        paymentMethods: [],
        showCardForm: true
    };

    componentDidMount() {
        this.fetchOptions();
    }

    fetchOptions = async () => {
        const customer = await this.fetchCustomer();
        const method = await this.fetchPaymentMethods();
        console.debug(customer);
        console.debug(method);
        // annoying way to handle initial onChange before setState finishes
        this.props.onChange({
            paymentMethod: method,
            billingDetails: {name: customer?.name, email: customer?.email}
        });
    };

    fetchCustomer = async () => {
        const {data} = await api.get('premium/customer');
        if (data && !data.deleted) {
            this.setState({
                customer: data,
                paymentMethod: data?.invoice_settings?.default_payment_method,
                email: data.email,
                name: data.name
            });
            return data;
        }
    };

    fetchPaymentMethods = async () => {
        const {data} = await api.get('premium/payment-methods');
        this.setState({paymentMethods: data, showCardForm: data.length <= 0});
        if (data && data.length > 0 && !this.state.paymentMethod) {
            this.setState({paymentMethod: data[0].id});
            return data[0];
        }

        if (this.state.paymentMethod) {
            return data.find((d : any) => d.id === this.state.paymentMethod);
        }
    };

    addPaymentMethod = async (response: stripe.PaymentMethodResponse) => {
        if (response.paymentMethod) {
            this.setState({
                showCardForm: false,
                paymentMethods: this.state.paymentMethods?.concat(response.paymentMethod)
            });
            this.changePaymentMethod(response.paymentMethod.id);
        }
    };

    showCardForm = () => {
        this.setState({showCardForm: true});
    };

    hideCardForm = () => {
        this.setState({showCardForm: false});
    };

    changePaymentMethod = (id: string) => {
        this.setState({paymentMethod: id});
        const method = this.state.paymentMethods?.find(m => m.id === id);
        if (method) {
            this.props.onChange({
                paymentMethod: method,
                billingDetails: {name: this.state.name, email: this.state.email}
            });
        }
    };

    changeName: ChangeEventHandler<HTMLInputElement> = (e) => {
        const name = e.target.value;
        this.setState({name});
        const method = this.state.paymentMethods?.find(m => m.id === this.state.paymentMethod);
        if (method) {
            this.props.onChange({paymentMethod: method, billingDetails: {name, email: this.state.email}});
        }
    };

    changeEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
        const email = e.target.value;
        this.setState({email});
        const method = this.state.paymentMethods?.find(m => m.id === this.state.paymentMethod);
        if (method) {
            this.props.onChange({paymentMethod: method, billingDetails: {name: this.state.name, email: email}});
        }
    };

    render() {
        const defaultMethod = this.state.customer?.invoice_settings?.default_payment_method;
        const paymentMethods = this.state.paymentMethods?.filter(p => p.id === defaultMethod).concat(this.state.paymentMethods?.filter(p => p.id !== defaultMethod)) ?? [];
        const email = this.state.email ?? "";
        const name = this.state.name ?? "";
        const validEmail = this.state.email == null || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email);
        const validName = this.state.name == null || this.state.name.length > 0;

        return (
            <div>
                {!this.state.customer &&
                <Form labelAlign='left'>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item required label='Name' labelCol={{span: 24}}
                                 validateStatus={!validName ? "warning" : undefined}
                                 help={!validName ? "Invalid name." : undefined}>
                        <Input required placeholder='Wumpus' value={name} onChange={this.changeName}/>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item required label='Email Address' labelCol={{span: 24}}
                                 validateStatus={!validEmail ? "warning" : undefined}
                                 help={!validEmail ? "Invalid email address." : undefined}>
                        <Input required placeholder='wumpus@puritybot.com' value={email} type='email'
                               onChange={this.changeEmail}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                }

                {!this.state.showCardForm && this.state.paymentMethods && this.state.paymentMethods.length > 0 &&
                <Form labelAlign='left'>
                  <Form.Item label='Choose a Payment Method' colon={false} labelCol={{span: 24}}>
                    <PaymentMethodList options={paymentMethods}
                                       value={this.state.paymentMethod} onChange={this.changePaymentMethod}/>
                  </Form.Item>

                  <Form.Item>
                    <Button htmlType='button' onClick={this.showCardForm}>{<PlusOutlined/>}Add a Payment
                      Method</Button>
                  </Form.Item>
                </Form>
                }

                {this.state.showCardForm &&
                <Elements>
                  <CardForm handleResult={this.addPaymentMethod} onCancel={this.hideCardForm}/>
                </Elements>
                }
            </div>
        );
    }
}

import * as React from "react";
import {ChangeEventHandler} from "react";
import {Alert, Button, Card, Col, Input, InputNumber, message, Row, Switch, Table} from "antd";
import SettingItem from "../../components/SettingItem";
import Loader from "../../stores/util/Loader";
import {SwitchChangeEventHandler} from "antd/es/switch";
import ChannelSelect from "../../components/ChannelSelect";
import {observer} from "mobx-react";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import MessagePreview from "../../components/MessagePreview";
import {StoreContext} from "../../stores/Stores";
import * as math from "mathjs";
import Help from "../../components/Help";
import RangeInput, {RangeValue} from "../../components/RangeInput";

interface LevelsState {
    error?: string | null;
    levels?: { level: number, xp: number }[];
}

@observer
export class Levels extends React.Component<GuildComponentProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    channelLoader = new Loader();
    state: LevelsState = {};

    componentDidMount(): void {
        const {guild} = this.props;

        if (guild.channels.length <= 0) {
            this.channelLoader.load(guild.fetchChannels());
        }

        this.calcLevels()
    }

    submit = async () => {
        if (this.isLevelEquationValid(this.props.guild.settings.levelEquation)) {
            try {
                await this.loader.load(this.props.guild.pushLevelSettingsToServer());
                message.success('Settings updated');
            } catch (e) {
                message.error('Something went wrong');
            }
        }
    };

    isLevelEquationValid = (equation?: string) => {
        if (!equation) {
            this.setState({error: null});
            return true;
        }

        if (equation.indexOf('@level') < 0) {
            this.setState({error: 'Level Equation must contain @level'});
            return false;
        }

        if (equation.length > 55) {
            this.setState({error: 'Level Equation is too long'});
            return false;
        }

        const noLevels = equation.replace(/@level/g, '');
        const checkLetters = /([A-Z]|[a-z])+/g.exec(noLevels);
        if (checkLetters && checkLetters.length > 0) {
            this.setState({error: 'Level Equation must not have letters other than @level'});
            return false;
        }

        try {
            const compiled = math.compile(equation.replace(/@level/g, 'x'));
            let prev = -1;
            for (let i = 1; i <= 1000; i++) {
                const xp = compiled.evaluate({x: i});
                if (xp <= prev) {
                    if (i > 1) {
                        this.setState({error: `Equation would require less xp for level ${i} than level ${i - 1}`});
                    } else {
                        this.setState({error: `Equation would require negative xp for level ${i}`});
                    }
                    return false;
                }
                if (xp > 10000000) {
                    this.setState({error: `Equation would require over 10 million xp for level ${i}`});
                    return false;
                }
                prev = xp;
            }
        } catch (e) {
            console.error(e);
            this.setState({error: `Equation is invalid`});
            return false;
        }

        this.setState({error: null});
        return true;
    };

    calcLevels = () => {
        const equation = this.props.guild.settings.levelEquation;
        if (!equation) {
            this.setState({levels: []});
            return;
        }

        try {
            const levels = [];
            const compiled = math.compile(equation.replace(/@level/g, 'x'));
            for (let i = 1; i <= 1000; i++) {
                const xp = compiled.evaluate({x: i - 1});
                levels.push({level: i, xp});
            }
            this.setState({levels});
        } catch (e) {
            this.setState({levels: []});
        }
    };

    changeNotifications: SwitchChangeEventHandler = (checked) => {
        this.props.guild.settings.setLevelNotifications(checked);
    };

    changeChannel = (channelId: string) => {
        this.props.guild.settings.setLevelNotificationsChannel(channelId);
    };

    changeMessage: ChangeEventHandler<HTMLTextAreaElement> = ({target}) => {
        this.props.guild.settings.setLevelMessage(target.value);
    };

    changeMessageExp = ({min, max}: RangeValue) => {
        if (min === max) {
            this.props.guild.settings.setMessageExp(min);
        } else {
            this.props.guild.settings.setMessageExp(`${min}-${max}`);
        }
    }

    changeXpCooldown = (seconds?: string | number) => {
        if(seconds) {
            if (typeof seconds === "string") {
                this.props.guild.settings.setXpCooldown(parseFloat(seconds) * 1000);
            } else {
                this.props.guild.settings.setXpCooldown(seconds * 1000);
            }
        }
    }

    changeEquation: ChangeEventHandler<HTMLInputElement> = ({target}) => {
        this.props.guild.settings.setLevelEquation(target.value);
        this.calcLevels();
    };

    render() {
        const {settings, textChannels} = this.props.guild;
        const {levelNotifications, levelNotificationsChannel, levelMessage, levelEquation, messageExp, xpCooldown} = settings.levelSettings;

        return (
            <div>
                <Card title='Level-up Notifications' style={{textAlign: "left"}}>
                    <SettingItem label='Send Level-up Notifications' inline>
                        <Switch checked={levelNotifications} onChange={this.changeNotifications}/>
                    </SettingItem>

                    <SettingItem label='Channel for Level-up Notifications'>
                        <ChannelSelect options={textChannels} value={levelNotificationsChannel}
                                       onChange={this.changeChannel}/>
                    </SettingItem>

                    <SettingItem label='Level-up Message'
                                 help='@user will be replaced with a mention of the user. @level will be replaced with their level.'
                                 wrapperWidth={24}>
                        <Row>
                            <Col xs={24} sm={24} md={10}>
                                <Input.TextArea value={levelMessage} onChange={this.changeMessage}/>
                            </Col>
                            <Col xs={24} sm={24} md={{span: 13, offset: 1}}>
                                <MessagePreview className={'level-up'} message={levelMessage}
                                                user={this.context.userStore.user}
                                                guild={this.props.guild}/>
                            </Col>
                        </Row>
                    </SettingItem>
                    <Button type='primary' onClick={this.submit} loading={this.loader.isLoading}>Save</Button>
                </Card>

                <Card title='Leveling Values' style={{textAlign: "left", marginTop: 20}}>
                    <SettingItem label={<Help label='XP per Message'
                                              help='Use different values to specify a range. The value given will be a random number within that range.
                                          The left number must be less than or equal to the right number.'/>}>
                        <RangeInput value={messageExp} min={0} max={100} onChange={this.changeMessageExp}/>
                    </SettingItem>

                    <SettingItem label={<Help label='XP Cooldown (seconds)'
                                              help='Determines how often a user can gain XP from a message.'/>}>
                        <InputNumber min={0} max={2147483647}
                                     value={(xpCooldown ?? 0) / 1000} onChange={this.changeXpCooldown}/>
                    </SettingItem>

                    <Row style={{marginTop: 20}}>
                        <Col xs={24} sm={24} md={10}>
                            <SettingItem label='Level Equation'
                                         help='Defines how much xp is needed for a particular level. The equation must include @level to determine how much XP is needed for @level + 1'
                                         extra={<Alert style={{display: this.state.error ? undefined : 'none'}}
                                                       type='error'
                                                       message={this.state.error}/>}
                                         wrapperWidth={24}>
                                <Input value={levelEquation} onChange={this.changeEquation}/>
                            </SettingItem>
                        </Col>
                        <Col xs={24} sm={24} md={{span: 13, offset: 1}}>
                            <Table className='xp-table' size='small' rowKey={'level'} pagination={false}
                                   scroll={{y: 150}}
                                   columns={[
                                       {
                                           title: 'Level',
                                           dataIndex: 'level'
                                       },
                                       {
                                           title: 'Required XP',
                                           dataIndex: 'xp',
                                           render: (xp) => xp.toLocaleString()
                                       }
                                   ]}
                                   dataSource={this.state.levels?.slice(0, 100)}/>
                        </Col>
                    </Row>

                    <Button type='primary' onClick={this.submit} loading={this.loader.isLoading}>Save</Button>
                </Card>
            </div>
        )
    }
}

export default Levels;

import * as React from "react";
import {Redirect, RouteComponentProps} from "react-router";
import {auth} from "../services/Authenticator";
import queryString from "query-string";
import Routes from "../Routes";
import {StoreContext} from "../stores/Stores";
import Loader from "../stores/util/Loader";
import {observer} from "mobx-react";
import {Spin} from "antd";
import Cookies from "js-cookie";
import config from "../app.config";

@observer
export class Login extends React.Component<RouteComponentProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();

    login = async (code: string) => {
        await auth.login(code);
    };

    generateState() {
        const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let state = "";
        for (let i = 0; i < 10; i++) {
            state += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        Cookies.set('login-state', state);
        return state;
    }

    componentDidMount() {
        if (this.props.location && this.props.location.search) {
            const {code, state} = queryString.parse(this.props.location.search);
            const loginState = Cookies.get('login-state');
            Cookies.remove('login-state');
            if (!(state && state === loginState)) {
                console.error('Invalid login state');
                auth.logout();
            }
            this.loader.load(this.login(code as string));
        }
    }

    render() {
        if (this.props.location && this.props.location.search) {
            if (this.loader.isLoaded) {
                const {from} = this.props.location.state || {from: {pathname: Routes.home}};
                return (
                    <Redirect to={from}/>
                )
            } else {
                return (
                    <Spin/>
                )
            }
        } else {
            auth.invalidateToken();
            const redirect_uri = encodeURIComponent(window.location.origin + Routes.login);
            window.location.href = `https://discordapp.com/api/oauth2/authorize?client_id=${config.client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=identify%20guilds&state=${this.generateState()}`;
            return <div/>;
        }
    }
}

export default Login;

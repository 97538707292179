import * as React from "react";
import {Card, Spin, Switch} from "antd";
import {observer} from "mobx-react";
import CommandTable from "./components/CommandTable";
import {SwitchChangeEventHandler} from "antd/es/switch";
import TabMenu from "../../components/TabMenu";
import Command from "../../stores/models/Command";
import {GuildComponentProps} from "../../types/GuildComponentProps";
import Loader from "../../stores/util/Loader";
import Error from "../../components/Error";
import {ERRORS} from "../../types/ServerError";

@observer
export class Commands extends React.Component<GuildComponentProps> {

    loader = new Loader();

    componentDidMount(): void {
        const {commandStore} = this.props.guild;
        this.loader.load(commandStore.fetchCommands());
    }

    onSelectGroup = ({value}: any) => {
        const {guild} = this.props;
        if (guild) {
            guild.commandStore.setCurrentGroup(value);
        }
    };

    changeGroupEnabled: SwitchChangeEventHandler = (checked) => {
        const {id, commandStore} = this.props.guild;
        const group = commandStore.currentGroup;
        group.setEnabled(checked);
        commandStore.pushGroupToServer(id, group);
    };

    camelCase = (name: string) => {
        return name.slice(0, 1).toUpperCase() + name.slice(1);
    };

    onCommandChange = (command: Command) => {
        const {id, commandStore} = this.props.guild;
        commandStore.pushCommandToServer(id, command);
    };

    render() {
        const guild = this.props.guild;
        if (!guild || !guild.settings) {
            return <div>loading</div>
        }
        const {commandStore} = guild;
        const {groups} = commandStore;
        const group = commandStore.currentGroupName ? groups[commandStore.currentGroupName] : undefined;
        const commands = group ? Object.values(group.commands) : [];
        const prefix = guild.settings.prefix ?? '!';

        return (
            <Card title='Commands' headStyle={{textAlign: "left"}} style={{textAlign: 'left'}}>
                {this.loader.showLoader && <Spin className='loader'/>}
                {this.loader.hasError &&
                <Error error={ERRORS.INTERNAL_SERVER_ERROR}/>
                }
                <TabMenu>
                    {Object.keys(groups).map(group =>
                        <TabMenu.Item key={group} value={group} active={group === commandStore.currentGroupName}
                                      onSelect={this.onSelectGroup}>
                            {this.camelCase(group)}
                        </TabMenu.Item>
                    )}
                </TabMenu>

                {!this.loader.isLoading && group &&
                <>
                  <div style={{marginTop: 20}}>
                    <label>Enable Group:</label>
                    <Switch checked={group.enabled} onChange={this.changeGroupEnabled} style={{marginLeft: 10}}/>
                  </div>
                  <CommandTable commands={commands} disabled={!group.enabled} onCommandChange={this.onCommandChange}
                                prefix={prefix}/>
                </>
                }
            </Card>
        )
    }
};

export default Commands;

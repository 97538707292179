import {action, computed, observable} from "mobx";

interface BasicSettings {
    prefix?: string;
    defaultPoints?: number;
    dailyBonus?: number;
    petSystem?: boolean;
    adminOnly?: boolean;
    voteIncentive?: number;
    levelEquation?: string;
}

interface WelcomeSettings {
    welcome?: string;
    welcomeStatus?: any;
    welcomeChannel?: string | null;
}

interface EconomySettings {
    pointSystem?: boolean;
    currencyName?: string;
    currencySymbol?: string;
    defaultPoints?: number;
    dailyBonus?: number;
    voteIncentive?: number;
    messagePoints?: string | number;
    workMoney?: string | number;
    crimeMoney?: string | number;
    exploreMoney?: string | number;
    fishMoney?: string | number;
    huntMoney?: string | number;
    complimentMoney?: string | number;
}

interface BirthdaySettings {
    bdayChannel?: string | null;
    bdayMessage?: string;
}

interface LevelSettings {
    levelNotifications?: boolean;
    levelNotificationsChannel?: string | null;
    levelEquation?: string;
    levelMessage?: string;
    messageExp?: (string | number);
    xpCooldown?: number;
}

interface ModerationSettings {
    empowerAdmins?: boolean;
    adminOnly?: boolean;
    adminRole?: string | null;
    modRole?: string | null;
    modLogs?: string | null;
    discordInviteLinks?: boolean;
    logImageChannel?: (string | null);
    wordBlacklist?: (string[] | null);
}

export class GuildSettings implements BasicSettings, EconomySettings, WelcomeSettings, BirthdaySettings, LevelSettings, ModerationSettings {
    @observable prefix?: string;
    @observable welcome?: string;
    @observable welcomeStatus?: any;
    @observable welcomeChannel?: string | null;
    @observable pointSystem?: boolean;
    @observable currencyName?: string;
    @observable currencySymbol?: string;
    @observable defaultPoints?: number;
    @observable dailyBonus?: number;
    @observable voteIncentive?: number;
    @observable messagePoints?: string | number;
    @observable workMoney?: string | number;
    @observable crimeMoney?: string | number;
    @observable exploreMoney?: string | number;
    @observable fishMoney?: string | number;
    @observable huntMoney?: string | number;
    @observable complimentMoney?: string | number;
    @observable petSystem?: boolean;
    @observable botChannel?: string | null;
    @observable musicChannel?: string | null;
    @observable petChannel?: string | null;
    @observable levelNotifications?: boolean;
    @observable levelNotificationsChannel?: string | null;
    @observable levelEquation?: string;
    @observable levelMessage?: string;
    @observable messageExp?: (string | number);
    @observable xpCooldown?: number;
    @observable empowerAdmins?: boolean;
    @observable adminOnly?: boolean;
    @observable adminRole?: string | null;
    @observable modRole?: string | null;
    @observable modLogs?: string | null;
    @observable discordInviteLinks?: boolean;
    @observable logImageChannel?: (string | null);
    @observable wordBlacklist?: (string[] | null);
    @observable bdayChannel?: string | null;
    @observable bdayMessage?: string;
    @observable autoRole?: boolean;

    constructor(json: any) {
        this.prefix = json.prefix;
        this.welcome = json.welcome;
        this.welcomeStatus = json.welcomeStatus;
        this.currencyName = json.currencyName;
        this.currencySymbol = json.currencySymbol;
        this.defaultPoints = json.defaultPoints;
        this.dailyBonus = json.dailyBonus;
        this.pointSystem = json.pointSystem;
        this.petSystem = json.petSystem;
        this.botChannel = json.botChannel;
        this.musicChannel = json.musicChannel;
        this.petChannel = json.petChannel;

        this.modRole = json.modRole;
        this.adminRole = json.adminRole;
        this.modLogs = json.modLogs;
        this.discordInviteLinks = json.discordInviteLinks;
        this.logImageChannel = json.logImageChannel;
        this.wordBlacklist = json.wordBlacklist;

        this.welcomeChannel = json.welcomeChannel;
        this.levelNotifications = json.levelNotifications;
        this.levelNotificationsChannel = json.levelNotificationsChannel;
        this.adminOnly = json.adminOnly;
        this.bdayChannel = json.bdayChannel;
        this.autoRole = json.autoRole;
        this.voteIncentive = json.voteIncentive;
        this.messagePoints = json.messagePoints;
        this.workMoney = json.workMoney;
        this.crimeMoney = json.crimeMoney;
        this.exploreMoney = json.exploreMoney;
        this.fishMoney = json.fishMoney;
        this.huntMoney = json.huntMoney;
        this.complimentMoney = json.complimentMoney;
        this.levelEquation = json.levelEquation;
        this.bdayMessage = json.bdayMessage;
        this.empowerAdmins = json.empowerAdmins;
        this.levelMessage = json.levelMessage;
        this.messageExp = json.messageExp;
        this.xpCooldown = json.xpCooldown;
    }

    @action setPrefix(prefix?: string) {
        this.prefix = prefix;
    }

    @action setWelcome(welcome?: string) {
        this.welcome = welcome;
    }

    @action setWelcomeStatus(welcomeStatus?: any) {
        this.welcomeStatus = welcomeStatus;
    }

    @action setCurrencyName(currencyName?: string) {
        this.currencyName = currencyName;
    }

    @action setCurrencySymbol(currencySymbol?: string) {
        this.currencySymbol = currencySymbol;
    }

    @action setDefaultPoints(defaultPoints?: number) {
        this.defaultPoints = defaultPoints;
    }

    @action setDailyBonus(dailyBonus?: number) {
        this.dailyBonus = dailyBonus;
    }

    @action setPointSystem(pointSystem?: boolean) {
        this.pointSystem = pointSystem;
    }

    @action setPetSystem(petSystem?: boolean) {
        this.petSystem = petSystem;
    }

    @action setBotChannel(botChannel?: string) {
        this.botChannel = botChannel ?? null;
    }

    @action setMusicChannel(musicChannel?: string) {
        this.musicChannel = musicChannel ?? null;
    }

    @action setPetChannel(petChannel?: string) {
        this.petChannel = petChannel ?? null;
    }

    @action setModRole(modRole?: string) {
        this.modRole = modRole ?? null;
    }

    @action setAdminRole(adminRole?: string) {
        this.adminRole = adminRole ?? null;
    }

    @action setModLogs(modLogs?: string) {
        this.modLogs = modLogs ?? null;
    }

    @action setDiscordInviteLinks(discordInviteLinks: boolean) {
        this.discordInviteLinks = discordInviteLinks;
    }

    @action setLogImageChannel(logImageChannel?: string) {
        this.logImageChannel = logImageChannel ?? null;
    }

    @action setWordBlacklist(wordBlacklist?: string[]) {
        this.wordBlacklist = wordBlacklist ?? null;
    }

    @action setWelcomeChannel(welcomeChannel?: string) {
        this.welcomeChannel = welcomeChannel ?? null;
    }

    @action setLevelNotifications(levelNotifications?: boolean) {
        this.levelNotifications = levelNotifications;
    }

    @action setLevelNotificationsChannel(levelNotificationsChannel?: string) {
        this.levelNotificationsChannel = levelNotificationsChannel ?? null;
    }

    @action setAdminOnly(adminOnly?: boolean) {
        this.adminOnly = adminOnly;
    }

    @action setBdayChannel(bdayChannel?: string) {
        this.bdayChannel = bdayChannel ?? null;
    }

    @action setAutoRole(autoRole?: boolean) {
        this.autoRole = autoRole;
    }

    @action setVoteIncentive(voteIncentive?: number) {
        this.voteIncentive = voteIncentive;
    }

    @action setMessagePoints(messagePoints?: string | number) {
        this.messagePoints = messagePoints;
    }

    @action setWorkMoney(workMoney?: string | number) {
        this.workMoney = workMoney;
    }

    @action setCrimeMoney(crimeMoney?: string | number) {
        this.crimeMoney = crimeMoney;
    }

    @action setExploreMoney(exploreMoney?: string | number) {
        this.exploreMoney = exploreMoney;
    }

    @action setFishMoney(fishMoney?: string | number) {
        this.fishMoney = fishMoney;
    }

    @action setHuntMoney(huntMoney?: string | number) {
        this.huntMoney = huntMoney;
    }

    @action setComplimentMoney(complimentMoney?: string | number) {
        this.complimentMoney = complimentMoney;
    }

    @action setLevelEquation(levelEquation?: string) {
        this.levelEquation = levelEquation;
    }

    @action setBdayMessage(bdayMessage?: string) {
        this.bdayMessage = bdayMessage;
    }

    @action setEmpowerAdmins(empowerAdmins?: boolean) {
        this.empowerAdmins = empowerAdmins;
    }

    @action setLevelMessage(levelMessage?: string) {
        this.levelMessage = levelMessage;
    }

    @action setMessageExp(messageExp?: string | number) {
        this.messageExp = messageExp;
    }

    /**
     * @param xpCooldown the xp cooldown in milliseconds
     */
    @action setXpCooldown(xpCooldown?: number) {
        this.xpCooldown = xpCooldown;
    }

    @computed
    get basicSettings(): BasicSettings {
        return {
            prefix: this.prefix,
            defaultPoints: this.defaultPoints,
            dailyBonus: this.dailyBonus,
            petSystem: this.petSystem,
            adminOnly: this.adminOnly,
            voteIncentive: this.voteIncentive,
            levelEquation: this.levelEquation
        }
    }

    @computed
    get welcomeSettings(): WelcomeSettings {
        return {
            welcome: this.welcome,
            welcomeStatus: this.welcomeStatus,
            welcomeChannel: this.welcomeChannel
        }
    }

    @computed
    get birthdaySettings(): BirthdaySettings {
        return {
            bdayMessage: this.bdayMessage,
            bdayChannel: this.bdayChannel
        }
    }

    @computed
    get levelSettings(): LevelSettings {
        return {
            levelNotifications: this.levelNotifications,
            levelNotificationsChannel: this.levelNotificationsChannel,
            levelEquation: this.levelEquation,
            levelMessage: this.levelMessage,
            messageExp: this.messageExp,
            xpCooldown: this.xpCooldown
        }
    }

    @computed
    get minMessageExp() {
        if (typeof this.messageExp === 'string') {
            const dashIndex = this.messageExp.indexOf('-');
            if (dashIndex >= 0) {
                return parseInt(this.messageExp.substring(0, dashIndex))
            } else {
                return parseInt(this.messageExp);
            }
        }

        return this.messageExp ?? 0;
    }

    @computed
    get maxMessageExp() {
        if (typeof this.messageExp === 'string') {
            const dashIndex = this.messageExp.indexOf('-');
            if (dashIndex >= 0) {
                return parseInt(this.messageExp.substring(dashIndex + 1))
            } else {
                return parseInt(this.messageExp);
            }
        }

        return this.messageExp ?? 0;
    }

    @computed
    get economySettings(): EconomySettings {
        return {
            pointSystem: this.pointSystem,
            currencyName: this.currencyName,
            currencySymbol: this.currencySymbol,
            defaultPoints: this.defaultPoints,
            dailyBonus: this.dailyBonus,
            voteIncentive: this.voteIncentive,
            messagePoints: this.messagePoints,
            workMoney: this.workMoney,
            crimeMoney: this.crimeMoney,
            exploreMoney: this.exploreMoney,
            fishMoney: this.fishMoney,
            huntMoney: this.huntMoney,
            complimentMoney: this.complimentMoney
        }
    }

    @computed
    get moderationSettings(): ModerationSettings {
        return {
            empowerAdmins: this.empowerAdmins,
            adminOnly: this.adminOnly,
            adminRole: this.adminRole,
            modRole: this.modRole,
            modLogs: this.modLogs,
            discordInviteLinks: this.discordInviteLinks,
            logImageChannel: this.logImageChannel,
            wordBlacklist: this.wordBlacklist
        }
    }

}

export default GuildSettings;

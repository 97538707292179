import {action, observable} from "mobx";
import {Editable} from "./Editable";
import Model from "../types/Model";

export class ReactRole extends Editable implements Model {
    id!: number;
    guildId!: string;
    @observable roleId!: string;
    @observable messageId!: string;
    @observable channelId!: string;
    @observable emoji!: string;

    constructor(json?: any) {
        super(json);
        if (json) {
            this.update(json)
        }
    }

    update(json: any) {
        this.id = json.id;
        this.guildId = json.guildId;
        this.roleId = json.roleId;
        this.messageId = json.messageId;
        this.channelId = json.channelId;
        this.emoji = json.emoji;
    }

    @action setRoleId(roleId: string) {
        this.roleId = roleId;
    }
    @action setMessageId(messageId: string) {
        this.messageId = messageId;
    }
    @action setChannelId(channelId: string) {
        this.channelId = channelId;
    }
    @action setEmoji(emoji: string) {
        this.emoji = emoji;
    }

    get isComplete() {
        return !!(this.roleId && this.emoji && this.channelId && this.messageId);
    }

}

export default ReactRole;

import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {Alert, Button, Input, Table, Tooltip} from "antd";
import {StoreContext} from "../../../stores/Stores";
import Loader from "../../../stores/util/Loader";
import {observer} from "mobx-react";
import SocialTracker from "../../../stores/models/SocialTracker";
import {TableProps} from "antd/es/table";
import ChannelSelect from "../../../components/ChannelSelect";
import {MinusOutlined, PlusOutlined} from '@ant-design/icons'

interface SocialTrackerTableProps extends TableProps<any> {
    trackers: SocialTracker[];
    onAdd: (tracker: SocialTracker) => void;
    onRemove: (trackerId: number) => void;
}

interface SocialTrackerTableState {
    error: string | null;
}

@observer
export class SocialTrackerTable extends React.Component<SocialTrackerTableProps> {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    loader = new Loader();
    trackerToAdd = new SocialTracker();

    state: SocialTrackerTableState = {
        error: null
    }

    componentDidMount() {
        this.loader.load(this.fetchOptions());
    }

    async fetchOptions() {
        await this.context.domainStore.guild!.fetchChannels();
    }

    changeName = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.trim();
        this.trackerToAdd.setName(value);
        const match = value.match(/.*([/ #.@]).*/);
        if (match) {
            this.setState({error: `Username must not contain symbols aside from _`});
            console.debug(match);
        } else if (value.length > 24) {
            this.setState({error: `Username is too long.`});
        } else if (!value.match(/^[\w]{0,24}$/)) {
            this.setState({error: `Username is invalid.`});
        } else {
            this.setState({error: null});
        }
    };

    changeChannelId = (channelId: string) => {
        this.trackerToAdd.setChannelId(channelId);
    };

    removeTracker = (trackerId: number) => {
        this.props.onRemove(trackerId);
    };

    addRank = async () => {
        await this.props.onAdd(this.trackerToAdd);
        this.trackerToAdd.update({}); // clear the rank to add
    };

    static trackerSorter(field: keyof SocialTracker) {
        return (a: SocialTracker, b: SocialTracker) => {
            if (!a.id || !b.id) return 0;
            if (!a[field] && !b[field]) return 0;
            if (a[field] && !b[field]) return 1;
            if (b[field] && !a[field]) return -1;
            if (typeof a[field] === "string") {
                return (a[field] as string)!.localeCompare(b[field] as string);
            } else if (typeof a[field] === "number") {
                return (a[field] as number) - (b[field] as number);
            }
            return 0;
        }
    }

    render() {
        const guild = this.context.domainStore.guild!;
        const {trackers, onAdd, onRemove, ...rest} = this.props;

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: 2,
                sorter: SocialTrackerTable.trackerSorter('id')
            },
            {
                title: 'Username',
                dataIndex: 'name',
                key: 'name',
                width: 6,
                sorter: SocialTrackerTable.trackerSorter('name'),
                render: (name: string | ReactNode) => {
                    if (typeof name === "string") {
                        return (
                            <a href={`https://www.twitch.tv/${name}`} target="_blank"
                               rel="noopener noreferrer">{name}</a>
                        )
                    }

                    return name;
                }
            },
            {
                title: 'Channel',
                dataIndex: 'channelId',
                key: 'channelId',
                width: 6,
                sorter: SocialTrackerTable.trackerSorter('channelId'),
                render: (channelId: string) => {
                    return guild.findChannelByID(channelId)?.name ?? channelId;
                }
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                width: 2
            }
        ];

        const data: any[] = trackers.map(r => {
            return {
                id: r.id,
                name: r.name,
                channelId: r.channelId,
                action: (
                    <Tooltip title='Remove' mouseEnterDelay={1}>
                        <Button type='primary' danger shape='circle' icon={<MinusOutlined/>} size='small'
                                onClick={() => this.removeTracker(r.id!)}/>
                    </Tooltip>
                )
            }
        });

        const loading = this.loader.isLoading;
        data.push({
            id: '',
            name: <Input disabled={loading} placeholder='Username' value={this.trackerToAdd.name}
                         onChange={this.changeName}/>,
            channelId: <ChannelSelect disabled={loading} options={guild.textChannels}
                                      value={this.trackerToAdd.channelId} onChange={this.changeChannelId}/>,
            action: (
                <Tooltip title='Add' mouseEnterDelay={1}>
                    <Button type='primary' shape='circle' icon={<PlusOutlined/>}
                            disabled={loading || !this.trackerToAdd.name || !this.trackerToAdd.channelId
                            || !!this.state.error || this.trackerToAdd.name.length < 3}
                            onClick={this.addRank}/>
                </Tooltip>
            )
        });

        return (
            <div>
                <Table bordered columns={columns} dataSource={data} rowKey="id"
                       pagination={false} {...rest}/>
                {this.state.error &&
                <Alert type="error" message={this.state.error}/>
                }
            </div>
        )
    }
}

export default SocialTrackerTable;

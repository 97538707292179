import * as React from "react";
import {Button, Card, Descriptions, message} from "antd";
import Giveaway from "../../../stores/models/Giveaway";
import Guild from "../../../stores/models/Guild";

interface GiveawayCardProps {
    giveaway: Giveaway;
    guild: Guild;
}

export const GiveawayCard = ({giveaway, guild}: GiveawayCardProps) => {
    const remove = async () => {
        try {
            giveaway.delete();
            guild.removeGiveaway(giveaway);
            message.success('Giveaway deleted');
        } catch (e) {
            message.error('Something went wrong');
        }
    };

    let channel = giveaway.channelId;
    if (channel) {
        channel = guild.findChannelByID(channel)?.name ?? channel;
    }

    let role = giveaway.roleId;
    if (role) {
        role = guild.findRoleByID(role)?.name ?? role;
    }

    return (
        <Card title={giveaway.prize} extra={<Button type='link' onClick={remove}>Delete</Button>}>
            <Card.Meta title='Requirements' description={
                <Descriptions bordered column={1} size='small'>
                    <Descriptions.Item label='End Time'>{giveaway.giveawayTime.format('YYYY-M-D H:m:s')}</Descriptions.Item>
                    <Descriptions.Item label='Number of Winners'>{giveaway.numWinners}</Descriptions.Item>
                    <Descriptions.Item label='Channel'>{channel}</Descriptions.Item>
                    <Descriptions.Item
                        label='Level Requirement'>{giveaway.levelRequirement ? `${giveaway.levelRequirement}+` : 'none'}</Descriptions.Item>
                    <Descriptions.Item label='Role Requirement'>{role}</Descriptions.Item>
                </Descriptions>
            }/>

            {/*TODO: Add winner if saved to BD*/}
            {/*{giveaway.winner &&
            <Card.Meta title='Winner'/>
            }*/}
        </Card>
    )
};

export default GiveawayCard;

import * as React from "react";
import {Select} from "antd";
import {SelectProps} from "antd/es/select";
import DiscordRole from "../stores/types/DiscordRole";
import Role from "./Role";

interface RoleSelectProps extends Omit<SelectProps<string>, 'value' | 'options'> {
    options: DiscordRole[];
    value?: string | null;
}

export const RoleSelect = ({options, value, ...props}: RoleSelectProps) => {

    const filterOption = (value: string, option: any) => {
        const role = options.find(m => m.id === option.key);
        return !!(role &&
            ((role.name && role.name?.toLowerCase().indexOf(value.toLowerCase()) >= 0)
                || role.id.toLowerCase().indexOf(value.toLowerCase()) >= 0));
    };

    return (
        <Select className='role-select' showSearch allowClear placeholder='Role'
                filterOption={filterOption} value={value ?? undefined} {...props}>
            {options.map(role => (
                <Select.Option className='role-option' key={role.id} value={role.id}>
                    <Role role={role}/>
                </Select.Option>
            ))}
        </Select>
    )
};

export default RoleSelect;

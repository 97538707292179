import * as React from "react";
import {Col, Row, Spin} from "antd";
import BirthdayList from "./scenes/BirthdayList";
import Loader from "../../stores/util/Loader";
import {observer} from "mobx-react";
import BirthdayConfig from "./scenes/BirthdayConfig";
import {GuildComponentProps} from "../../types/GuildComponentProps";

@observer
export class Birthdays extends React.Component<GuildComponentProps> {

    memberLoader = new Loader();
    channelLoader = new Loader();

    componentDidMount(): void {
        this.fetchOptions();
    }

    componentDidUpdate(prevProps: Readonly<GuildComponentProps>): void {
        if (prevProps.guild.id !== this.props.guild.id) {
            this.fetchOptions();
        }
    }

    fetchOptions() {
        const {guild} = this.props;
        if (guild.members.length <= 0) {
            this.memberLoader.load(guild.fetchGuildMembers());
        }

        if (guild.channels.length <= 0) {
            this.channelLoader.load(guild.fetchChannels());
        }
    }

    render() {
        const {guild} = this.props;

        if (this.memberLoader.isLoading) {
            return <Spin/>
        }

        return (
            <Row gutter={10}>
                <Col lg={12} sm={24} xs={24}>
                    <BirthdayList guild={guild}/>
                </Col>
                <Col lg={12} sm={24} xs={24}>
                    <BirthdayConfig guild={guild}/>
                </Col>
            </Row>
        )
    }
}

export default Birthdays;

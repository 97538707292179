import * as React from "react";
import {StoreContext} from "../stores/Stores";
import Routes from "../Routes";
import GeneralConfig from "../scenes/GeneralConfig/GeneralConfig";
import Commands from "../scenes/Commands/Commands";
import Economy from "../scenes/Economy/Economy";
import Levels from "../scenes/Levels/Levels";
import Ranks from "../scenes/Ranks/Ranks";
import Moderation from "../scenes/Moderation/Moderation";
import Birthdays from "../scenes/Birthdays/Birthdays";
import Giveaways from "../scenes/Giveaways/Giveaways";
import Twitch from "../scenes/Twitch/Twitch";
import {Route} from "react-router";
import ReactRoles from "../scenes/ReactRoles/ReactRoles";
import ServerShop from "../scenes/ServerShop/ServerShop";
import {Spin} from "antd";
import Tickets from "../scenes/Tickets/Tickets";
import PremiumWrapper from "./PremiumWrapper";

export class GuildWrapper extends React.Component {
    static contextType = StoreContext;
    context!: React.ContextType<typeof StoreContext>;

    render() {
        const guild = this.context.domainStore.guild;

        return (
            <div>
                {!guild ?
                    <Spin/>
                    :
                    <div>
                        <Route exact path={[Routes.general, Routes.guild]}
                               render={() => <GeneralConfig guild={guild}/>}/>
                        <Route exact path={Routes.commands} render={() => <Commands guild={guild}/>}/>
                        <Route exact path={Routes.economy} render={() => <Economy guild={guild}/>}/>
                        <Route exact path={Routes.leveling} render={() => <Levels guild={guild}/>}/>
                        <Route exact path={Routes.ranks} render={() => <Ranks guild={guild}/>}/>
                        <Route exact path={Routes.reactRoles} render={() => <ReactRoles guild={guild}/>}/>
                        <Route exact path={Routes.moderation} render={() => <Moderation guild={guild}/>}/>
                        <Route exact path={Routes.birthdays} render={() => <Birthdays guild={guild}/>}/>
                        <Route exact path={Routes.giveaways} render={() => <Giveaways guild={guild}/>}/>
                        <Route exact path={Routes.serverShop} render={() => <ServerShop guild={guild}/>}/>
                        <Route exact path={Routes.twitch} render={() => <Twitch guild={guild}/>}/>
                        <Route exact path={Routes.tickets}
                               render={(props) => <PremiumWrapper guild={guild} component={Tickets} {...props}/>}/>
                    </div>
                }
            </div>
        )
    }
}

export default GuildWrapper;

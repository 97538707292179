import {action, observable} from "mobx";

/**
 * Adds observable properties for tracking if an item is being edited and if it's new (hasn't been created yet)
 */
export class Editable {

    @observable editing: boolean = false;
    @observable isNew: boolean = false;

    constructor(json?: any) {
        this.editing = !!json.editing;
        this.isNew = !!json.isNew;
    }

    @action update(json: any) {
        this.editing = !!json.editing;
        this.isNew = !!json.isNew;
    }

    @action setEditing(editing: boolean) {
        this.editing = editing;
    }
}

export default Editable;
